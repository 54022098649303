import InvoiceStatisticsTable from "./InvoiceStatisticsTable";

import {Grid, Typography} from "@material-ui/core";
import React, { Component } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {ColorProvider, texisionGray, white} from "../../../../util/ColorTheme";
import { GeneralContext } from "../../../contexts/GeneralContext";
import { de } from "date-fns/locale";
import { InvoiceDiagramEmptyMessage } from "./InvoiceDiagramEmptyMessage";
import { StatisticsDatePickers } from "../StatisticsDatePickers";
import { InvoiceStatisticsMetricSelect } from "./InvoiceStatisticsMetricSelect";
import { InvoiceStatisticsSelectButton } from "./InvoiceStatisticsSelectButton";
import { InvoiceStatisticsItemSelection } from "./InvoiceStatisticsItemSelection";
import ChartTypeSelection from "../ChartTypeSelection";
import InvoiceStatisticsLineChart from "./InvoiceStatisticsLineChart";
import InvoiceStatisticsBarChart from "./InvoiceStatisticsBarChart";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

class InvoiceStatisticsContainer extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);

        this.globalMinDate = null;
        this.globalMaxDate = null;

        this.state = {
            chartType: "line",
            colorProvider: new ColorProvider(),
            accumulated: false,
            showItemSelection: false,
            activeKey: null,
            activeInvoiceId: null,
            selectedNodes: [],
            bufferedNodes: [],
            selectedTab: "priceTotal",
            period: {min: {month: null, year: null}, max: {month: null, year: null}}
        }
    }

    componentDidMount() {
        this.initializePeriod();
    }

    componentDidUpdate(_, prevState) {
        if (prevState.selectedNodes.length === 0 && this.state.selectedNodes.length !== 0) {
            this.props.onDiagramDataChange(true);
        } else if (prevState.selectedNodes.length !== 0 && this.state.selectedNodes.length === 0) {
            this.props.onDiagramDataChange(false);
        }
    }

    initializePeriod = () => {
        const period = this.state.period;
        const allInvoiceDates = this.props.invoices
            ?.map(i => this.mapInvoiceDateToFirstDayOfMonth(i.invoiceDate))
            ?.sort((a,b) => a.getTime() - b.getTime());

        if (allInvoiceDates && allInvoiceDates.length > 0) {
            let minDate = allInvoiceDates[0];
            const maxDate = allInvoiceDates[allInvoiceDates.length-1];
            this.globalMinDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
            this.globalMaxDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1);
            period.min = {month: minDate.getMonth(), year: minDate.getFullYear()};
            period.max = {month: maxDate.getMonth(), year: maxDate.getFullYear()};
            this.setState({period});
        }
    }

    onChartTypeChange = (chartType) => {
        this.setState({chartType});
        this.props.onItemSelected(null, null);
    }

    isMonthDisabled = (date) => {
        if (!this.props.invoices || this.props.invoices.length === 0) {
            return true;
        }
        return !this.props.invoices
            .map(invoice => new Date(invoice.invoiceDate))
            .find(invoiceDate => invoiceDate.getMonth() === date.getMonth() 
                && invoiceDate.getFullYear() === date.getFullYear());
    }

    mapInvoiceDateToFirstDayOfMonth = (invoiceDateInMillis) => {
        const invoiceDate = new Date(invoiceDateInMillis);
        return new Date(invoiceDate.getFullYear(), invoiceDate.getMonth(), 1);
    }

    cancelSelection = () => {
        this.setState({bufferedNodes: [...this.state.selectedNodes], showItemSelection: false});
    }

    saveSelection = () => {
        this.setState({selectedNodes: [...this.state.bufferedNodes], showItemSelection: false});
        this.props.onItemSelected(null, null);
    }

    onItemChecked = (node, checked) => {
        let bufferedNodes = this.state.bufferedNodes;
        if (!checked) {
            this.state.colorProvider.releaseColor(node.key);
            bufferedNodes = bufferedNodes.filter(i => i.key !== node.key);
        } else {
            node.color = this.state.colorProvider.getColor(node.key);
            bufferedNodes.push(node);
        }
        this.setState({bufferedNodes});
    }

    getXAxisTickLabel = (invoiceId) => {
        const invoiceDate = this.props.invoices?.find(i => i.id === invoiceId)?.invoiceDate;
        return new Date(invoiceDate).toLocaleString(de, {month: "long"}) + " " + new Date(invoiceDate).getFullYear();
    }

    onMinDateChange = (newDate) => {
        const min = {month: newDate.getMonth(), year: newDate.getFullYear()};
        const period = this.state.period;
        period.min = min;
        this.setState({period});
    }

    onMaxDateChange = (newDate) => {
        const max = {month: newDate.getMonth(), year: newDate.getFullYear()};
        const period = this.state.period;
        period.max = max;
        this.setState({period});
    }

    hasNoPeriod = () => {
        const period = this.state.period;
        return !!(
            (!period.min.month && period.min.month !== 0) 
            || !period.min.year 
            || (!period.max.month && period.max.month !== 0) 
            || !period.max.year
        );
    }

    sortedInvoices = () => {
        let maxDate = new Date(this.state.period.max.year, this.state.period.max.month, 1).getTime();
        let minDate = new Date(this.state.period.min.year, this.state.period.min.month, 1).getTime();
        if (!minDate || !maxDate) {
            return null;
        }

        return this.props.invoices?.filter(invoice => {
            const invoiceDateMappedToFirstDay = this.mapInvoiceDateToFirstDayOfMonth(invoice.invoiceDate).getTime();
            return invoiceDateMappedToFirstDay >= minDate && invoiceDateMappedToFirstDay <= maxDate;
        })?.sort((a, b) => a.invoiceDate - b.invoiceDate);
    }

    render() {

        const { activeKey, activeInvoiceId, bufferedNodes, period, selectedNodes, selectedTab, showItemSelection } = this.state;

        const { selectedInvoiceId, selectedInvoiceItem, statisticItemMap } = this.props;

        const hasNoContractItems = !this.props.contractItemMap || this.props.contractItemMap.size === 0
        const hasNoPeriod = this.hasNoPeriod();

        const chartProps = {
            activeKey: activeKey,
            accumulated: this.state.accumulated,
            activeInvoiceId: activeInvoiceId,
            getXAxisTickLabel: this.getXAxisTickLabel,
            sortedInvoices: this.sortedInvoices(),
            onHideTooltip: () => this.setState({activeKey: null, activeInvoiceId: null}),
            onItemSelected: (key, invoiceId) => this.props.onItemSelected(key, invoiceId),
            onShowTooltip: (activeKey, activeInvoiceId) => this.setState({activeKey, activeInvoiceId}),
            statisticItemMap: statisticItemMap,
            selectedNodes: selectedNodes,
            selectedInvoiceId: selectedInvoiceId,
            selectedInvoiceItem: selectedInvoiceItem,
            selectedTab: selectedTab
        }

        let Chart;
        if (this.state.chartType === "line") {
            Chart = InvoiceStatisticsLineChart;
        } else if (this.state.chartType === "bar") {
            Chart = InvoiceStatisticsBarChart;
        } else if (this.state.chartType === "table") {
            Chart = InvoiceStatisticsTable;
        }

        return (
            <div style={{marginBottom: 20}}>

                <InvoiceStatisticsItemSelection
                    bufferedNodes={bufferedNodes}
                    cancelSelection={() => this.cancelSelection()}
                    rootNode={this.props.rootNode}
                    invoices={this.props.invoices}
                    onItemChecked={(contractItem, checked) => this.onItemChecked(contractItem, checked)}
                    contractItemMap={this.props.contractItemMap}
                    saveSelection={() => this.saveSelection()}
                    showItemSelection={showItemSelection}/>

                <Grid container>

                    <Grid item xs={12}>

                        <Grid container alignItems="center" spacing={2}>

                            <Grid item xs>
                                <InvoiceStatisticsSelectButton
                                    hasNoContractItems={hasNoContractItems}
                                    hasNoPeriod={hasNoPeriod}
                                    onClick={() => this.setState({showItemSelection: true})}/>
                            </Grid>

                            <Grid item>
                                <StatisticsDatePickers
                                    labelIdMin="cockpit.statistics.invoices.minDate"
                                    labelIdMax="cockpit.statistics.invoices.maxDate"
                                    disabled={!this.props.invoices || this.props.invoices.length === 0}
                                    globalMinDate={this.globalMinDate}
                                    globalMaxDate={this.globalMaxDate}
                                    minDate={new Date(period.min.year, period.min.month, 1)}
                                    maxDate={new Date(period.max.year, period.max.month, 1)}
                                    onMinDateChange={(newDate) => this.onMinDateChange(newDate)}
                                    onMaxDateChange={(newDate) => this.onMaxDateChange(newDate)}
                                    isMonthDisabled={(date) => this.isMonthDisabled(date)}/>
                            </Grid>

                        </Grid>
                        
                    </Grid>

                    <Grid 
                        item xs={12} 
                        style={{
                            backgroundColor: white, 
                            marginTop: 30, 
                            minWidth: 300, 
                            overflowY: "hidden", 
                            overflowX: selectedNodes && selectedNodes.length > 0 ? "scroll" : "hidden"
                        }}>

                        {!!selectedNodes?.length && (
                            <div style={{marginTop: 15, paddingLeft: 40, paddingRight: 40}}>
                            <Grid container spacing={4} alignItems="center">
                            <Grid item xs={2}>
                                <InvoiceStatisticsMetricSelect
                                    selectedTab={selectedTab}
                                    onTabChange={(newValue) => this.setState({selectedTab: newValue})}/>
                            </Grid>
                            <Grid item xs={10}>
                                <ChartTypeSelection
                                    accumulated={this.state.accumulated}
                                    onAccumulatedChange={(accumulated) => this.setState({accumulated})}
                                    chartType={this.state.chartType}
                                    onChartTypeChange={(chartType) => this.onChartTypeChange(chartType)}
                                />
                            </Grid>
                            </Grid>
                            </div>
                        )}

                        <InvoiceDiagramEmptyMessage
                            hasNoPeriod={this.hasNoPeriod()} 
                            invoices={this.props.invoices}
                            mapInvoiceDateToFirstDayOfMonth={this.mapInvoiceDateToFirstDayOfMonth}
                            contractItemMap={this.props.contractItemMap}
                            selectedNodes={selectedNodes}
                            selectedMaxDate={new Date(period.max.year, period.max.month, 1).getTime()}
                            selectedMinDate={new Date(period.min.year, period.min.month, 1).getTime()}/>

                        {!!selectedNodes?.length && (
                            <>
                                <Chart {...chartProps}/>

                                <Typography
                                    variant="body2"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: 20,
                                        marginLeft: 30,
                                        marginBottom: 20,
                                        textAlign: "center"
                                    }}>
                                    <InfoOutlinedIcon style={{color: texisionGray, marginRight: 10}}/>
                                    <FormattedMessage id="statistics.legend.hint"/>
                                </Typography>
                            </>
                        )}

                    </Grid>

                </Grid>

            </div>
        );
    }
}

export default injectIntl(InvoiceStatisticsContainer);
