import React, {Component} from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import {CardActionArea, Tooltip} from "@material-ui/core";
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ValidationIcon from "./ValidationIcon";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import {getProjectTaskIcon} from "../../util/ValidationUtil";
import '../../css/Validation.css';
import {wordBreak} from "../../util/TableUtil";
import {APP_TYPE_KEY, COCKPIT_APP, PROJECT_STATUS_IS_PUBLISHED} from "../../util/Constants";
import {getActiveOperation} from "../../services/OperationService";
import {getActiveProject, isProjectTaskCompleted} from "../../services/ProjectService";
import {isCockpit, isTender} from "../../util/Util";

class TenderTile extends Component {

    static contextType = GeneralContext;

    render() {
        const Comp = this.props.onTileClicked ? CardActionArea : Grid;
        const IconComponent = this.props.IconComponent ?? getProjectTaskIcon(this.props.projectTask);

        return (
            <Grid
                key={this.props.projectTask}
                item
                className={"tileGrid"}>

                <Card
                    className={this.props.class ?? "tileCard"}
                    onClick={() => this.props.onTileClicked?.call(this.props.projectTask)}>
                    <Comp>

                            <Grid container justifyContent="center" style={{height: 130, padding: 15}}>

                                <Grid item xs={12} style={{fontWeight: "bold", textAlign: "center", wordBreak: "break-word", fontSize: 12}}>
                                    {wordBreak(
                                        this.props.concreteTitle ?? this.props.intl.formatMessage({
                                        id: this.props.title ?? ("validation." + this.props.projectTask)}))}

                                </Grid>

                                <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                    <IconComponent fontSize="large"/>
                                </Grid>

                                <Grid item xs={12}>
                                    {this.props.onCreate && this.props.mayCreate
                                        && <Tooltip title={this.props.createDisabled && this.props.createDisabledTooltip ? this.props.createDisabledTooltip : ""}>
                                            <div>
                                                <IconButton disabled={this.props.createDisabled} onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.props.onCreate();
                                                }}>
                                                    <AddIcon/>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    }

                                    {this.props.onEdit && this.props.mayEdit
                                        && <IconButton onClick={(e) => {
                                            e.stopPropagation();
                                            this.props.onEdit();
                                        }}>
                                            <EditIcon/>
                                        </IconButton>
                                    }

                                    {this.props.onDelete && this.props.mayDelete
                                        && <IconButton onClick={(e) => {
                                            e.stopPropagation();
                                            this.props.onDelete();
                                        }}>
                                            <DeleteForeverIcon/>
                                        </IconButton>
                                    }
                                </Grid>

                            </Grid>

                    </Comp>
                </Card>

                {((this.props.complete === true || this.props.complete === false || this.props.projectTask)
                    && ((isCockpit() && getActiveOperation(this.context)?.editable && getActiveOperation(this.context)?.workingProject)
                            || (isTender() && getActiveProject(this.context)?.status !== PROJECT_STATUS_IS_PUBLISHED))
                    && (localStorage.getItem(APP_TYPE_KEY) !== COCKPIT_APP || getActiveOperation(this.context)?.editable))
                    && <div style={{position: "absolute", top: -5, right: 5}}>

                        <ValidationIcon
                            complete={this.props.complete ?? isProjectTaskCompleted(this.context, this.props.projectTask, this.props.objectId)}
                            size={20}/>

                    </div>
                }

            </Grid>
        );
    }
}

export default injectIntl(withRouter(TenderTile));
