import {RESET_PASSWORD_ROUTE} from '../util/Constants';
import {createSuccessMessage, createErrorMessage} from '../util/Util';

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
export const STAGE = process.env.REACT_APP_STAGE || "dev";

const axios = require('axios');
// The Authorization header is set here, because otherwise the native browser login window will be shown for the first request.
const axiosInstance = createAxiosInstance(true);

// axios instance without credentials is needed for downloading documents directly from s3 space
const axiosInstanceWithoutCredentials = createAxiosInstance(false);

function createAxiosInstance(withCredentials) {
	let parameters = {
		baseURL: BACKEND_URL,
		timeout: 200000,
	};
	if (withCredentials) {
		parameters.withCredentials = true;
		parameters.headers = {
			'Access-Control-Allow-Origin': '*',
			'Authorization': 'BEARER'
		};
	} else {
		parameters.withCredentials = false;
	}
	return axios.create(parameters);
}

function errorHandler(error) {
	return error.response;
}

export function get(path) {
	return axiosInstance.get(path).then(function (response) {
		return response;
	}).catch(errorHandler);
}

export function deleteRequest(path) {
	return axiosInstance.delete(path).then(function (response) {
		return response;
	}).catch(errorHandler);
}

export async function deleteRequestAsync(path) {
	return await axiosInstance.delete(path).catch(errorHandler);
}

export function downloadUsingPost(path, payload) {
	return axiosInstance.post(path, payload, {
		timeout: 200000,
		responseType: 'arraybuffer'
	}).then(function (response) {
		return response;
	}).catch(errorHandler);
}

export async function downloadUsingGet(path, withoutCredentials) {
	const instance = withoutCredentials ? axiosInstanceWithoutCredentials : axiosInstance;
	return await instance.get(path, {
		responseType: 'arraybuffer'
	}).then(function (response) {
		return response;
	}).catch(errorHandler);
}

export function post(path, payload) {
	return axiosInstance.post(path, payload).then(function (response) {
		return response;
	}).catch(errorHandler);
}

export async function postAsync(path, payload, withCredentials = true) {
	const instance = withCredentials ? axiosInstance : axiosInstanceWithoutCredentials;
	return await instance.post(path, payload).catch(errorHandler);
}

export async function postAsyncCatch(context, path, payload, props, hideSuccess, errorTextMap, replacements) {
	let response = await postAsync(path, payload);
	return handleResult(context, props, response, "save", hideSuccess, errorTextMap, replacements);
}

export async function putAsync(path, payload) {
	return await axiosInstance.put(path, payload).catch(errorHandler);
}

export async function putAsyncCatch(context, path, payload, props, hideSuccess, errorTextMap, replacements) {
	let response = await putAsync(path, payload);
	return handleResult(context, props, response, "update", hideSuccess, errorTextMap, replacements);
}

export async function getAsync(path, withCredentials = true) {
	const instance = withCredentials ? axiosInstance : axiosInstanceWithoutCredentials;
	return await instance.get(path).catch(errorHandler);
}

export async function getAsyncCatch(context, path, props, errorTextMap, replacements, withCredentials = true) {
	const response = await getAsync(path, withCredentials);
	return handleResult(context, props, response, "get", true, errorTextMap, replacements);
}

export async function deleteAsync(path, withCredentials = true) {
	const instance = withCredentials ? axiosInstance : axiosInstanceWithoutCredentials;
	return await instance.delete(path).catch(errorHandler);
}

export async function deleteAsyncCatch(context, path, props, errorTextMap, hideSuccess, replacements) {
	let response = await deleteAsync(path);
	return handleResult(context, props, response, "delete", hideSuccess, errorTextMap, replacements);
}

export function handleResult(context, props, response, method, hideSuccess, errorTextMap, replacements) {
	let status = response?.status;
	let data = response.data;
	switch (status) {
		case 200:
			!hideSuccess && createSuccessMessage(props.intl.formatMessage({id: "commons." + method + ".message.success"}), props);
			return (data !== null && data !== undefined && data !== "") ? data : true;
		case 400:
			const error400 = errorTextMap?.get(status) ?? "commons.badRequest.error";
			createErrorMessage(props.intl.formatMessage({id: error400}, replacements), props);
			break;
		case 402:
			const error402 = errorTextMap?.get(status) ?? "commons.paymentRequired.error";
			createErrorMessage(props.intl.formatMessage({id: error402}, replacements), props);
			break;
		case 401:
		case 403:
			if (data.message === "PROJECT_PUBLISHED_REASON") {
				createErrorMessage(props.intl.formatMessage({id: "commons.project.published.error"}), props);
			} else {
				createErrorMessage(props.intl.formatMessage({id: "commons." + method + ".message.error"}), props);
				context.logout();
			}
			break;
		case 404:
			const error404 = errorTextMap?.get(status) ?? "commons.notFound.error";
			createErrorMessage(props.intl.formatMessage({id: error404}, replacements), props);
			break;
		case 405:
			const error405 = errorTextMap?.get(status) ?? "commons.methodNotAllowed.error";
			createErrorMessage(props.intl.formatMessage({id: error405}, replacements), props);
			break;
		case 406:
			const error406 = errorTextMap?.get(status) ?? "commons.notAcceptable.error";
			createErrorMessage(props.intl.formatMessage({id: error406}, replacements), props);
			break;
		case 409:
			context.setUnsavedChanges(false);
			context.setShowOptimisticLockDialog(true);
			break;
		case 500:
			const error500 = errorTextMap?.get(status) ?? "commons.internalServer.error";
			createErrorMessage(props.intl.formatMessage({id: error500}, replacements), props);
			break;
		default:
			const errorDefault = errorTextMap?.get(status) ?? "commons." + method + ".message.error";
			createErrorMessage(props.intl.formatMessage({id: errorDefault}, replacements), props);
			break;
	}
	return false;
}

export async function deleteImage(path) {
	return await axiosInstance.delete(path).catch(errorHandler);
}

export async function uploadImage(path, image) {
	const formData = new FormData();
	formData.append("file", image.file);
	return await axiosInstance.post(path, formData, {headers: {'Content-Type': 'multipart/form-data'}}).catch(errorHandler);
}

export async function uploadBlobCatch(context, path, blob, username, props, hideSuccess, errorTextMap, replacements) {
	let response = await uploadBlob(path, blob, username);
	return handleResult(context, props, response, "save", hideSuccess, errorTextMap, replacements);
}

export async function uploadBlob(path, blob, username) {

	const formData = new FormData();
	formData.append("file", blob);

	if (username) {
		formData.append("username", username)
	}

	return await axiosInstance.post(path, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
	    }
	}).catch(errorHandler);
}

export async function postJsonAndBlob(path, jsonKey, json, files) {
	const formData = new FormData();
	if (files && files.length > 0) {
		for (let i = 0; i < files.length; i++) {
			formData.append("files", files[i]);
		}
	} else {
		formData.append("files", null);
	}
	formData.append(jsonKey, new Blob([JSON.stringify(json)], {type: "application/json"}));

	return await axiosInstance.post(path, formData, {
		headers: {
			'Content-Type': undefined
		}
	}).catch(errorHandler);
}

export async function login(username, password) {
	const urlencoded = new URLSearchParams();
	urlencoded.append("user", username);
	urlencoded.append("password", password);

	return await axiosInstance.post('/login', urlencoded, {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
	}).then(function (response) {
		return response;
	}).catch(errorHandler);
}

export async function requestPasswordReset(username) {
	const urlencoded = new URLSearchParams();
	urlencoded.append("username", username);

	return await axiosInstanceWithoutCredentials.post('/requestpasswordreset', urlencoded, {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		}
	}).then(function (response) {
		return response;
	}).catch(errorHandler);
}

export async function resetPassword(token, password) {
	const urlencoded = new URLSearchParams();
	urlencoded.append("token", token);
	urlencoded.append("password", password);

	return await axiosInstanceWithoutCredentials.post(RESET_PASSWORD_ROUTE, urlencoded, {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		}
	}).then(function (response) {
		return response;
	}).catch(errorHandler);
}

/**
  Standard Form POST that targets a new window. 
  Once the browser reads the attachment header on the server response, it will close the new tab and begin the download. 
  This same approach also happens to work nicely for displaying a resource like a PDF in a new tab.

  https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication
 */
export function downloadStream(url, token) {
    let form = document.createElement('form')
    form.method = 'post'
    form.target = '_blank'
    form.action = BACKEND_URL + url;
    form.innerHTML = '<input type="hidden" name="token" value="' + token + '">'

    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
}
