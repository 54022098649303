import {deleteAsyncCatch, getAsync, getAsyncCatch, postAsyncCatch, putAsyncCatch} from "./BackendService";
import {getActiveOperation} from "./OperationService";
import {
    LAST_OPERATION_ID,
    LAST_PROJECT_ID,
    PROJECT_STATUS_IN_EDITING,
    PROJECT_STATUS_IN_PROGRESS,
    PROJECT_STATUS_IS_PUBLISHED,
    PROJECT_TASKS
} from "../util/Constants";
import {convertValidationObjectToMap} from "../util/ValidationUtil";
import {isTender} from "../util/Util";

export function getActiveProject(context) {
    if (isTender()) {
        let activeProjectId = context.appData.activeProjectId;
        return context.appData.allProjects?.find(p => p.id === activeProjectId);
    }
    const activeOperation = getActiveOperation(context);
    if (activeOperation && activeOperation.activeProject.id === context.appData.activeProjectId) {
        return activeOperation.activeProject;
    } else if (activeOperation 
        && activeOperation.workingProject?.id === context.appData.activeProjectId) 
    {
        return activeOperation.workingProject;
    } else {
        return null;
    }
}

export function getOfferProject(context) {
    if (isTender()) {
        let offerProjectId = context.appData.offerProjectId ?? context.appData.activeProjectId;
        return getActiveOperation(context)?.projects?.find(p => p.id === offerProjectId);
    } else {
        return null;
    }
}

export function getOfferRound(context, projectId) {
    let offerProjectId = projectId ?? context.appData.offerProjectId;
    let activeOperation = getActiveOperation(context);
    if (!activeOperation) {
        return
    }
    return activeOperation.projects
        .sort((a,b) => a.createdAt - b.createdAt)
        .findIndex(p => p.id === offerProjectId) + 1;
}

export function getNextOfferRound(context) {
    return getOfferRound(context) + 1;
}


export async function updateValidationMap(context, projectId) {
    let id = projectId;
    if (id === null || id === undefined || id === "null" || id === "undefined") {
        if (isTender()) {
            id = context.appData.activeProjectId;
        } else {
            const activeOperation = getActiveOperation(context);
            id = activeOperation?.workingProject?.id;
        }
    }
    if (id === null || id === undefined || id === "null" || id === "undefined") {
        return;
    }
    const response = await getAsync("/project/validation/" + id);
    if (response?.status === 200) {
        context.setValidationMap(convertValidationObjectToMap(response.data.validationMap));
    }
}

export async function getPublishedProjects(context, props) {
    const projects = await getAsyncCatch(context, "/project/published", props);
    return projects ? projects : [];
}

export async function getPublishedProjectById(context, props, projectId) {
    const publishedProject = await getAsyncCatch(context, "/project/published/" 
        + projectId, props);
    return publishedProject ? publishedProject : null;
}

export async function updateProject(context, props, project, hideSuccess) {
    return await putAsyncCatch(context, "/project/" + project.id, project, props, hideSuccess);
}

export async function deleteProjectForce(context, props, projectId){
    return await deleteAsyncCatch(context, "/project/dummy/" + projectId, props);
}

export async function createDummyProject(context, props, status) {
    const createdProject = await postAsyncCatch(context, "/project/dummy/" 
        + status, null, props);
    return createdProject ? createdProject : null;
}

export async function getStatistics(context, props, projectId) {
    const statisticsVo = await getAsyncCatch(context, "/project/statistics/" 
        + projectId, props);
    return statisticsVo ? statisticsVo : null;
}

export async function getSnapshotsByProjectId(context, props, projectId) {
    const snapshots = await getAsyncCatch(context, "/project/snapshots/" 
        + projectId, props);
    return snapshots ? snapshots : [];
}

export async function recallProject(context, props, recallVo) {
    return await putAsyncCatch(context, "/project/recall", recallVo, props);
}

export async function isNoDataAllowedForProjectTask(projectTask, objectId) {
    let response;
    switch (projectTask) {
        case PROJECT_TASKS.CUSTOMER_ARTICLES:
            response = await getAsync('/customerarticles/' + objectId);
            break;
        case PROJECT_TASKS.RESIDENTS_LAUNDRY:
            response = await getAsync("/residentslaundry/" + objectId);
            if (response?.data) {
                response.data = response.data.filter(r => r.selected === true);
            }
            break;
        case PROJECT_TASKS.OPERATING_RESOURCES:
            response = await getAsync("/operating/resource/unit/" + objectId);
            break;
        case PROJECT_TASKS.SPECIAL_SERVICES:
            response = await getAsync("/special/service/unit/" + objectId);
            break;
        default:
            response = null;
    }
    return response?.status === 200 && response?.data.length === 0;
}

export function isProjectTaskCompleted(context, projectTask, objectId) {
    if (!context.appData?.validationMap) {
        return false;
    }
    return context.appData.validationMap.get(projectTask)?.get(objectId)?.complete;
}

export async function cancelEditingProject(context, props, operationId, onSuccess, onError) {
    const operation = await deleteAsyncCatch(context,"/operation/" + operationId 
        + "/project", props);
    if (operation) {
        localStorage.setItem(LAST_PROJECT_ID, operation.workingProject?.id);
        await context.reloadAppData();
        onSuccess();
    } else {
        onError();
    }
}

export async function publishProject(context, props, operation, onSuccess, onError) {
    const updateVo = {
        projectId: operation.workingProject?.id,
        projectStatus: PROJECT_STATUS_IS_PUBLISHED
    };
    const success = await putAsyncCatch(context,"/operation/" + operation.id 
        + "/project", updateVo, props);
    if (success) {
        await context.reloadAppData();
        onSuccess();
    } else {
        onError();
    }
}

export async function saveEditingProject(context, props, operation, comment, onSuccess, onError) {
    const updateVo = {
        projectId: operation.workingProject?.id,
        projectStatus: PROJECT_STATUS_IS_PUBLISHED,
        comment
    };
    const success = await putAsyncCatch(context,"/operation/" + operation.id 
        + "/project", updateVo, props);
    if (success) {
        await context.reloadAppData();
        onSuccess();
    } else {
        onError();
    }
}

export async function startEditingProject(context, props, operation, onSuccess, onError) {
    const updateVo = {
        projectId: operation.activeProject.id,
        projectStatus: PROJECT_STATUS_IN_EDITING
    };
    const updatedOperation = await putAsyncCatch(context,"/operation/" 
        + operation.id + "/project", updateVo, props);
    if (updatedOperation) {
        localStorage.setItem(LAST_PROJECT_ID, updatedOperation.workingProject?.id);
        localStorage.setItem(LAST_OPERATION_ID, updatedOperation.id);
        await context.reloadAppData();
        onSuccess();
    } else {
        onError();
    }
}

export async function unpublishProject(context, props, operation, onSuccess, onError) {
    const updateVo = {
        projectId: operation.activeProject.id,
        projectStatus: PROJECT_STATUS_IN_PROGRESS
    };
    const success = await putAsyncCatch(context, "/operation/" + operation.id 
        + "/project", updateVo, props);
    if (success) {
        await context.reloadAppData();
        onSuccess();
    } else {
        onError();
    }
}

export function isPublished(project){
    return project?.status !== PROJECT_STATUS_IN_PROGRESS && project?.status !== PROJECT_STATUS_IN_EDITING;
}

export function isPreviousProject(context) {
    const projectId = context.appData.activeProjectId;
    const offerProjectId = context.appData.offerProjectId;
    return projectId !== offerProjectId;
}

export function getPreviousProject(context) {
    let sortedProjects = getActiveOperation(context).projects.sort((a,b) => a.createdAt - b.createdAt);
    if (sortedProjects.length >= 2) {
        return sortedProjects.at(sortedProjects.length - 2);
    } else {
        return null;
    }
}

export function getActiveProjectByOperationId(context, operationId) {
    let map = context.appData?.participatingProjectMap;
    let id = parseInt(operationId);

    if (map && map.has(id) && map.get(id).length > 0) {
        // get the newest project of the given operation
        let sorted = context.appData?.participatingProjectMap
            .get(parseInt(operationId))
            .sort((a,b) => a.createdAt - b.createdAt);

        return sorted[sorted.length - 1];
    } else {
        return getActiveProject(context);
    }
}