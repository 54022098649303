import {Component} from "react";
import {ResponsiveContainer} from "recharts";
import {injectIntl} from "react-intl";

class CategoryStatisticsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeCategory: null,
            activeTime: null
        }
    }

    render() {
        return (
            <ResponsiveContainer debounce={300} width="100%" aspect={2.6} minWidth={900}>
                <div>Not supported, yet!</div>
            </ResponsiveContainer>);
    }
}
export default injectIntl(CategoryStatisticsTable);