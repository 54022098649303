import React from 'react';
import { Component } from 'react';
import { texisionGreen, texisionOrange, white } from '../../../util/ColorTheme';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

import '../../../css/NavBar.css';
import { Avatar, ListItemIcon } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { GeneralContext } from '../../contexts/GeneralContext';
import ValidationIcon from "../../validation/ValidationIcon";
import {PROJECT_STATUS_IS_PUBLISHED} from "../../../util/Constants";
import {getProjectTaskIcon} from "../../../util/ValidationUtil";
import {getActiveOperation} from "../../../services/OperationService";
import {getActiveProject, isProjectTaskCompleted} from "../../../services/ProjectService";
import {isCockpit, isTender} from "../../../util/Util";

class NavBarItemIcon extends Component {

    static contextType = GeneralContext;

    simpleIcon = () => {
        let Icon;
        if (this.props.IconComponent) {
            Icon = this.buildIconComponent();
        } else if (this.props.projectTask) {
            Icon = this.buildIconComponent(getProjectTaskIcon(this.props.projectTask));
        } else if (this.props.icon) {
            Icon = <ListItemIcon className={this.props.icon}/>;
        } else {
            Icon = <div/>;
        }

        if (this.props.showHint) {
            return (
                <div style={{display: "flex", position: "relative"}}>

                    {Icon}

                    <div style={{position: "absolute", right: 8}}>

                        <Avatar style={{backgroundColor: texisionOrange, height: 10, width: 10}}>
                            <div style={{color: texisionOrange, height: 10, width: 10}}/>
                        </Avatar>

                    </div>

                </div>
            );
        }
        return Icon;
    }

    singleValidationIcon = () => {
        let insideIcon;
        if (!isProjectTaskCompleted(this.context, this.props.projectTask, this.props.objectId)) {
            insideIcon = <div/>;
        } else {
            insideIcon = <ValidationIcon complete={true} size={18}/>;
        }

        return (
            <ListItemIcon>
                <Avatar style={{backgroundColor: white, height: 25, width: 25}}>
                    <Avatar style={{backgroundColor: white, height: 18, width: 18}}>
                        {insideIcon}
                    </Avatar>
                </Avatar>
            </ListItemIcon>
        );
    }

    buildIcon = (icon) => {
        return !icon
            ? this.buildIconComponent()
            : <ListItemIcon className={icon}/>
    }

    buildIconComponent = (SpecificIcon) => {
        const { IconComponent, selected } = this.props;
        const colorClass = selected ? "menu-icon-selected" : "menu-icon-default";
        const ResultIcon = SpecificIcon ?? IconComponent;
        return <ListItemIcon><ResultIcon className={colorClass}/></ListItemIcon>
    }

    summarizedValidationIcon = () => {
        let icon;
        let backgroundColor;
        let size;
        if (!isProjectTaskCompleted(this.context, this.props.projectTask, this.props.objectId)) {
            size = 14;
            icon = <MoreHorizRoundedIcon style={{color: white, height: size, width: size}}/>;
            backgroundColor = texisionOrange;
        } else {
            size = 16;
            icon = <CheckCircleRoundedIcon style={{color: texisionGreen, height: size, width: size, backgroundColor: white}}/>;
            backgroundColor = "transparent";
        }

        return (
            <div style={{display: "flex", position: "relative"}}>

                {this.buildIcon(this.props.icon)}

                <div style={{position: "absolute", top: -5, right: 5}}>

                    <Avatar style={{backgroundColor: backgroundColor, height: size, width: size}}>
                        {icon}
                    </Avatar>

                </div>

            </div>
        );
    }

    render() {
        let icon;
        if (!this.props.projectTask
            || (isCockpit() && (!getActiveOperation(this.context)?.editable
                || this.props.projectId === getActiveOperation(this.context)?.activeProject?.id))
            || (isTender() && getActiveProject(this.context)?.status === PROJECT_STATUS_IS_PUBLISHED)) {
            icon = this.simpleIcon();
        } else if (this.props.singleValidation) {
            icon = this.singleValidationIcon()
        } else {
            icon = this.summarizedValidationIcon();
        }

        return (
            <div style={{paddingLeft: this.props.level > 1 ? (this.props.level - 1) * 40 : 0}}>
                {icon}
            </div>
        );
    }
}

export default injectIntl(NavBarItemIcon);
