import {getAsync} from "./BackendService";


export async function loadProfiles(context){
    let response = await getAsync("/profiles");
    if (response?.status === 200) {
        return response.data;
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    }
    return [];
}