import React from 'react';
import { Component } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { injectIntl } from 'react-intl';
import {primaryColor} from '../../../util/ColorTheme';
import { APP_TYPE_KEY, COCKPIT_APP, getNavBarTitleId, LAST_PROJECT_ID } from '../../../util/Constants';

import '../../../css/NavBar.css';
import { GeneralContext } from "../../contexts/GeneralContext";
import { withRouter } from "react-router-dom";
import {Icon, Tooltip} from '@material-ui/core';
import NavBarItemIcon from './NavBarItemIcon';
import { Role } from '../../../services/UserService';
import {isCockpit} from "../../../util/Util";


class NavBarItem extends Component {

    static contextType = GeneralContext;

    hideItem = () => {
        if (this.context.currentUser.role !== Role.ADMIN
            && this.props.roles 
            && !this.props.roles.includes(this.context.currentUser.role)) {
            return true;
        } else if (isCockpit()) {
            const activeOperationId = this.context.appData.activeOperationId;
            return activeOperationId === null || activeOperationId === undefined;
        } else {
            return false;
        }
    }

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        } else if (this.props.pathname) {
            if (this.props.unitId) {
                this.context.setActiveUnitId(this.props.unitId);
            } else if (this.props.offerProject) {
                this.context.setOfferProjectId(this.props.offerProject.id);
            }

            // for COCKPIT setting the project id is necessary because there are two projects
            // clickable for each operation (active and working project)
            if (isCockpit() && this.props.projectId) {
                this.context.setActiveProjectId(this.props.projectId);
                localStorage.setItem(LAST_PROJECT_ID, this.props.projectId);
            }
            this.props.history.push(this.props.pathname);
        }
    }

    isSelected = () => {
        // if cockpit is used, the project id must be identical as well
        const extraConditionForCockpit = localStorage.getItem(APP_TYPE_KEY) !== COCKPIT_APP 
            || this.props.projectId === null 
            || this.props.projectId === undefined 
            || this.props.projectId === this.context.appData.activeProjectId;

        return this.props.pathname === window.location.pathname
            && (this.props.unitId === null || this.props.unitId === undefined || this.props.unitId === this.context.appData.activeUnitId)
            && (this.props.offerProject === null || this.props.offerProject === undefined || this.props.offerProject.id === this.context.appData.offerProjectId)
            && extraConditionForCockpit;
    }

    getTitle = () => {
        if (this.props.directTitle) {
            return this.props.directTitle;
        } else if (this.props.title) {
            return this.props.intl.formatMessage({id: this.props.title});
        } else {
            return this.props.intl.formatMessage({id: getNavBarTitleId(this.props.pathname)});
        }
    }

    render() {
        if (!this.props.alwaysVisible && this.hideItem()) {
            return null;
        }

        return (
            <Tooltip
                title={this.props.disabled && this.props.disabledTitle ? this.props.intl.formatMessage({id: this.props.disabledTitle}) : ""}>

                <div>

                    <MenuItem
                        disableRipple
                        button
                        dense
                        className={this.props.open || this.isSelected() ? "open" : null}
                        selected={this.isSelected()}
                        onClick={() => this.onClick()}
                        style={{backgroundColor: this.isSelected() ? "var(--bodyBackgroundColor)" : null, height: 45}}
                        disabled={this.props.disabled}>

                        <NavBarItemIcon
                            icon={this.props.icon}
                            IconComponent={this.props.IconComponent}
                            selected={this.isSelected()}
                            showHint={this.props.showHint}
                            projectId={this.props.projectId}
                            projectTask={this.props.projectTask}
                            objectId={this.props.objectId}
                            singleValidation={this.props.singleValidation}
                            level={this.props.level}
                            unitId={this.props.unitId}/>

                        <ListItemText
                            className="title"
                            style={{color: this.isSelected() && !this.props.open ? primaryColor : null, overflow: "visible"}}
                            primary={this.getTitle()}/>

                        {this.props.open === undefined
                            ? <div/>
                        : <Icon
                            className={this.props.open ? "icon-caret-up-large" : "icon-caret-down-large"}
                            style={{fontSize: "10pt"}}/>}

                    </MenuItem>

                </div>
            </Tooltip>
        );
    }
}

export default injectIntl(withRouter(NavBarItem));
