import {
    COCKPIT_APP,
    LAST_OPERATION_ID,
    LAST_PROJECT_ID,
    PROJECT_STATUS_IN_NEGOTIATION,
    PROJECT_STATUS_IN_POST_PROCESSING, PROJECT_STATUS_IN_PROGRESS,
    PROJECT_STATUS_IS_ACCEPTED, PROJECT_STATUS_IS_PUBLISHED,
    PROJECT_STATUS_WITHDRAW_REQUESTED, TENDER_APP
} from "../util/Constants";
import {deleteAsync, postAsync, postAsyncCatch, putAsync, putAsyncCatch} from "./BackendService";
import {getActiveProject, updateProject} from "./ProjectService";
import {isTender} from "../util/Util";

export function getActiveOperation(context) {
    return context.appData.operations?.find(o => o.id === context.appData.activeOperationId);
}

export function getOperationByProjectId(context, projectId) {
    return context.appData.operations?.find(o => o.activeProject.id === projectId);
}

export function setOperation(context, operation) {
    let operations = context.appData.operations.filter(o => o.id !== operation.id);
    operations.push(operation);
    context.setOperationList(operations);
    if (operations?.length === 1) {
        localStorage.setItem(LAST_OPERATION_ID, operation.id);
        context.appData.activeOperationId = operation.id;
    }
    let projectIdToUnitsMap = context.appData.projectIdToUnitsMap;
    if (operation?.activeProject) {
        projectIdToUnitsMap.set(operation.activeProject.id, operation.activeProject.businessUnits?.units ?? []);
    } else if (operation.workingProject) {
        projectIdToUnitsMap.set(operation.workingProject.id, operation.workingProject.businessUnits?.units ?? []);
    }
    context.setProjectIdToUnitsMap(projectIdToUnitsMap);
}

export function setOperationAndProject(context, operation) {
    let operations = context.appData.operations.filter(o => o.id !== operation.id);
    operations.push(operation);
    context.setOperationList(operations);
    let project = operation.workingProject || operation.activeProject;

    let allProjects = context.appData.allProjects.filter(p => p.id !== project.id);
    allProjects.push(project);
    context.setProjectList(allProjects);

    if (allProjects?.length === 1) {
        localStorage.setItem(LAST_PROJECT_ID, project.id);
        context.appData.activeProjectId = project.id;
        localStorage.setItem(LAST_OPERATION_ID, operation.id);
        context.appData.activeOperationId = operation.id;
    }
    let projectIdToUnitsMap = context.appData.projectIdToUnitsMap;
    projectIdToUnitsMap.set(project.id, project.businessUnits?.units);
    context.setProjectIdToUnitsMap(projectIdToUnitsMap);
}

export async function startWorkingState(context, operation, newestProjectId, effectiveFrom, onSuccess, onError) {
    const updateVo = {
        projectId: newestProjectId,
        effectiveFrom: effectiveFrom,
        projectStatus: PROJECT_STATUS_IN_POST_PROCESSING
    }
    await updateProjectStatus(context, operation?.id, updateVo, onSuccess, onError);
}

export async function publishWorkingState(context, operation, comment, onSuccess, onError) {
    const updateVo = {
        projectId: operation?.workingProject?.id,
        projectStatus: PROJECT_STATUS_IN_NEGOTIATION,
        comment: comment,
        effectiveFrom: operation?.workingProject?.effectiveFrom
    }
    await updateProjectStatus(context, operation?.id, updateVo, onSuccess, onError);
}

export async function saveWorkingState(context, operation, onSuccess, onError) {
    const updateVo = {
        projectId: operation?.workingProject?.id,
        projectStatus: PROJECT_STATUS_IS_ACCEPTED,
        effectiveFrom: operation?.workingProject?.effectiveFrom
    }
    await updateProjectStatus(context, operation?.id, updateVo, onSuccess, onError);
}

export async function deleteWorkingState(context, operation, onSuccess, onError) {
    const response = await deleteAsync("/operation/" + operation?.id + "/project");
    if (response?.status === 200) {
        await context.reloadAppData();
        onSuccess();
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    } else {
        onError();
    }
}

export async function acceptWorkingState(context, operation, comment, onSuccess, onError) {
    const updateVo = {
        projectId: operation?.workingProject?.id,
        projectStatus: PROJECT_STATUS_IS_ACCEPTED,
        comment: comment,
        effectiveFrom: operation?.workingProject?.effectiveFrom
    }
    await updateProjectStatus(context, operation?.id, updateVo, onSuccess, onError);
}

export async function rejectWorkingState(context, operation, comment, onSuccess, onError) {
    const updateVo = {
        projectId: operation?.workingProject?.id,
        projectStatus: PROJECT_STATUS_IN_POST_PROCESSING,
        comment: comment,
        effectiveFrom: operation?.workingProject?.effectiveFrom
    }
    await updateProjectStatus(context, operation?.id, updateVo, onSuccess, onError);
}

export async function requestWithdraw(context, operation, effectiveFrom, projectIdToWithdraw, comment, onSuccess, onError) {
    const updateVo = {
        projectId: projectIdToWithdraw,
        projectStatus: PROJECT_STATUS_WITHDRAW_REQUESTED,
        comment: comment,
        effectiveFrom: effectiveFrom
    }
    await updateProjectStatus(context, operation?.id, updateVo, onSuccess, onError);
}

export async function acceptWithdraw(context, operation, effectiveFrom, projectIdToWithdraw, comment, onSuccess, onError) {
    const updateVo = {
        projectId: projectIdToWithdraw,
        projectStatus: PROJECT_STATUS_IN_POST_PROCESSING,
        comment: comment,
        effectiveFrom: effectiveFrom
    }
    await updateProjectStatus(context, operation?.id, updateVo, onSuccess, onError);
}

export async function rejectWithdraw(context, operation, effectiveFrom, projectIdToWithdraw, comment, onSuccess, onError) {
    const updateVo = {
        projectId: projectIdToWithdraw,
        projectStatus: PROJECT_STATUS_IS_ACCEPTED,
        comment: comment,
        effectiveFrom: effectiveFrom
    }
    await updateProjectStatus(context, operation?.id, updateVo, onSuccess, onError);
}

export async function prepareNextOfferRound(context, onSuccess, onError) {
    let activeProject = getActiveProject(context)
    if (activeProject?.status === PROJECT_STATUS_IS_PUBLISHED) {
        let projectId = activeProject.id
        const updateVo = {
            projectId: projectId,
            projectStatus: PROJECT_STATUS_IN_PROGRESS
        }
        await updateProjectStatus(context, activeProject.operationId, updateVo, onSuccess, onError);
    } else {
        console.log("project status IS_PUBLISHED required.", activeProject?.status)
        onError();
    }
}

async function updateProjectStatus(context, operationId, updateVo, onSuccess, onError){
    const response = await putAsync("/operation/" + operationId + "/project", updateVo);
    if (response?.status === 200) {
        await context.reloadAppData();
        onSuccess();
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    } else {
        onError();
    }
}

export async function reloadOperation(context, operation) {
    await context.reloadAppData();
    if (isTender()) {
        await setOperationAndProject(context, operation);
    } else {
        await setOperation(context, operation);
    }
}

export async function switchToOperation(context, newOperation) {
    // user has to be loaded after creating new projects so the list of assigned operations is up-to-date
    await context.loadUser();
    localStorage.setItem(LAST_PROJECT_ID, newOperation.workingProject?.id);
    localStorage.setItem(LAST_OPERATION_ID, newOperation.id);
    switch (newOperation.operationType) {
        case "ORIGIN_COCKPIT":
            await context.setActiveApp(COCKPIT_APP);
            break;
        default:
        case "ORIGIN_TENDER":
            await context.setActiveApp(TENDER_APP);
            break;
    }
}

export async function createOperation(context, props, operation) {
    let operationResult = await postAsyncCatch(context, "/operation", operation, props);
    await switchToOperation(context, operationResult);
    return operationResult;
}

export async function updateOperation(context, props, operation, project) {
    const hideSuccess = true;
    await updateProject(context, props, project);
    let operationResult = await putAsyncCatch(this.context, "/operation/" + operation.id, operation, props, hideSuccess);
    await reloadOperation(context, operationResult);
    return operationResult;
}

export async function copyOperation(context, operation, onSuccess, onError) {
    const operationId = getActiveOperation(context).id
    const response = await postAsync("/operation/" + operationId + "/copy", operation);
    if (response?.status === 200 && !!response.data) {
        await switchToOperation(context, response.data)
        onSuccess();
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    } else {
        onError();
    }
}
