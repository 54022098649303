import {Card, CardContent, IconButton, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Grid } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { withRouter } from "react-router-dom";
import history from "../navigation/shared/History";
import {LICENSE_TYPES, PAYMENT_MANAGEMENT_ROUTE} from '../../util/Constants';
import {TabPanel} from "../uiLibrary/TabPanel";
import Product from "./Product";
import MajorCustomerInformation, {MAJOR_CUSTOMER_INFORMATION_TYPE} from "./MajorCustomerInformation";
import { withSnackbar } from "notistack";
import {getProductsAndPrices} from "../../services/PaymentService";

class PublicProductsOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: new Map(),
            prices: new Map(),
            isLoading: true,
            selectedTabIndex: 0
        }
    }

    async componentDidMount() {
        const productsPricesVo = await getProductsAndPrices(this.state.context, this.props);
        const products = productsPricesVo?.products ? new Map(Object.entries(productsPricesVo.products)) : new Map();
        const prices = productsPricesVo?.prices ? new Map(Object.entries(productsPricesVo.prices)) : new Map();
        this.setState({prices, products, isLoading: false});
    }

    onSelectProduct = (productId) => {
        history.push(PAYMENT_MANAGEMENT_ROUTE + "?productId=" + productId);
    }

    cardForLicenseType = (licenseType) => {
        const product = Array.from(this.state.products).map(([productId, product]) => product)
            .find((product) => product.metadata.licenseType === licenseType);
        const price = Array.from(this.state.prices).map(([priceId, price]) => price).find(price => price.product === product.id);
        return (
            <div style={{textAlign: "center"}}>
                <h2>
                    <FormattedMessage id={"payment.licenseType.title." + licenseType}/>
                </h2>

                <Typography variant="subtitle2">
                    <FormattedMessage id={"payment.public.product." + licenseType + ".subtitle"}/>
                </Typography>

                <Product
                    product={product}
                    price={price}
                    showInfoIcon={true}
                    isPublic={true}
                    onSelectProduct={() => this.onSelectProduct(product.id)}/>
            </div>
        );
    }

    render() {
        return (
            <Card style={{margin: "3%", minWidth: 300}}>
                <CardContent>

                    <Grid container>

                        <Grid item xs style={{display: "flex", justifyContent: "flex-start", alignItems: "flex-start"}}>
                            <Tooltip title={this.props.intl.formatMessage({id: "commons.back.button"})}>
                                <IconButton 
                                    aria-haspopup="true" 
                                    onClick={() => this.props.history.goBack()} 
                                    color="inherit">
                                    <ArrowBackIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Typography variant="h1" style={{textAlign: "center"}}>
                                <FormattedMessage id="prices.title"/>
                            </Typography>
                        </Grid>

                        <Grid item xs/>

                    </Grid>

                    <div style={{display: "flex", justifyContent: "center", marginBottom: 20}}>
                        <Tabs
                            className="public-prices-tab"
                            value={this.state.selectedTabIndex}
                            onChange={(event, newValue) => this.setState({selectedTabIndex: newValue})}
                            indicatorColor="primary"
                            textColor="primary">
                            <Tab
                                key="prices-tender-tab"
                                label={this.props.intl.formatMessage({id: "prices.tender.tab.title"})}
                                disableRipple={true}/>
                            <Tab
                                key="prices-offer-tab"
                                label={this.props.intl.formatMessage({id: "prices.offer.tab.title"})}
                                disableRipple={true}/>
                        </Tabs>
                    </div>

                    <TabPanel value={this.state.selectedTabIndex} index={0}>

                        <Typography variant="subtitle2" style={{textAlign: "center"}}>
                            <FormattedMessage id="prices.tender.subtitle"/>
                        </Typography>

                        <Grid container spacing={4} justifyContent="space-evenly" style={{marginBottom: 40}}>
                            <Grid item xs={12} md={6}>
                                {this.cardForLicenseType(LICENSE_TYPES.TENDER_LICENSE)}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {this.cardForLicenseType(LICENSE_TYPES.COOPERATION_LICENSE)}
                            </Grid>
                        </Grid>

                        <MajorCustomerInformation type={MAJOR_CUSTOMER_INFORMATION_TYPE.BOTH}/>

                    </TabPanel>

                    <TabPanel value={this.state.selectedTabIndex} index={1}>

                        <Typography variant="subtitle2" style={{textAlign: "center"}}>
                            <FormattedMessage id="prices.offer.subtitle"/>
                        </Typography>

                    </TabPanel>

                </CardContent>
            </Card>
        );
    }
}

export default withRouter(injectIntl(withSnackbar(PublicProductsOverview)));
