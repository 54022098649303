import { useIntl } from "react-intl";
import React from "react";
import {Select} from "@material-ui/core";

const InvoiceStatisticsMetricSelect = ({onTabChange, selectedTab}) => {

    const intl = useIntl();

    return (
        <Select
            native
            value={selectedTab}
            label={intl.formatMessage({id: "cockpit.statistics.metric.select.label"})}
            onChange={(e) => onTabChange(e.target.value)}
            inputProps={{name: 'net-gross-select', id: 'nett-gross-native-simple'}}>
            <option key={"priceTotal-option"} value={"priceTotal"}>
                {intl.formatMessage({id: "priceSheet.column.priceTotal"})}
            </option>
            <option key={"pricePerCase-option"} value={"pricePerCase"}>
                {intl.formatMessage({id: "priceSheet.column.pricePerCase"})}
            </option>
            <option key={"pricePerDay-option"} value={"pricePerDay"}>
                {intl.formatMessage({id: "priceSheet.column.pricePerDay"})}
            </option>
            <option key={"price-option"} value={"price"}>
                {intl.formatMessage({id: "priceSheet.column.price"})}
            </option>
            <option key={"priceDelta-option"} value={"priceDelta"}>
                {intl.formatMessage({id: "priceSheet.column.priceDelta"})}
            </option>
            <option key={"amount-option"} value={"amount"}>
                {intl.formatMessage({id: "priceSheet.column.amount"})}
            </option>
            <option key={"amountDelta-option"} value={"amountDelta"}>
                {intl.formatMessage({id: "priceSheet.column.amountDelta"})}
            </option>
            <option key={"amountPerCase-option"} value={"amountPerCase"}>
                {intl.formatMessage({id: "priceSheet.column.amountPerCase"})}
            </option>
            <option key={"amountPerDay-option"} value={"amountPerDay"}>
                {intl.formatMessage({id: "priceSheet.column.amountPerDay"})}
            </option>
        </Select>
    );
}

export {
    InvoiceStatisticsMetricSelect
}
