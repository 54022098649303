import React, {Component} from 'react';

import {texisionBlue, texisionWarningOrange} from '../../util/ColorTheme';
import {createErrorMessage} from '../../util/Util'
import {Role} from '../../services/UserService';

import {FormattedMessage, injectIntl} from 'react-intl';

import {postAsync} from '../../services/BackendService';
import TexisionContainer from '../uiLibrary/TexisionContainer';
import {Button, Grid, TextField, Tooltip, Typography, DialogContentText} from '@material-ui/core';
import {COUNTRIES, DIALOG_TYPE_INFO, LOGIN_ROUTE} from '../../util/Constants';
import {withSnackbar} from 'notistack';
import {GeneralContext} from "../contexts/GeneralContext";
import {withRouter} from "react-router-dom";
import { IconButton, InputAdornment } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import TexisionDialog from '../uiLibrary/TexisionDialog';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import history from "../navigation/shared/History";

class Registration extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            mail: this.props.location.state?.mail ?? "", // mail address is username
            password: "",
            role: this.props.location.state?.role ?? Role.BIDDER_READ_ONLY,
            passwordVisible: false,
            isRegistering: false,
            showFromNewsletterDialog: false,
        }
    }

    componentDidMount() {
        const requestParameters = new URLSearchParams(window.location.search);
        const mailEncoded = requestParameters.get("email");
        if (mailEncoded) {
            const mailDecoded = Buffer.from(mailEncoded, "base64").toString("utf-8");
            this.setState({mail: mailDecoded, showFromNewsletterDialog: true, role: Role.BIDDER});
        }
    }

    validateEmailAddress = () => {
        return this.state.mail && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.mail);
    }

    validatePassword = () => {
        return this.state.password?.length >= 3;
    }

    validateRequiredFields = () => {
        return this.validateEmailAddress() && this.validatePassword();
    }

    createUser = async() => {
        this.setState({isRegistering: true});

        const {mail, password, role} = this.state;

        let newUser = {
            username: mail.toLowerCase(), 
            email: mail, 
            role,
            password,
            country: COUNTRIES.DE,
            operationIds: [],
            activated: false,
            registrationComplete: false
        };

        const requestParameters = new URLSearchParams(window.location.search);
        const mailEncoded = requestParameters.get("email");

        // if user comes from newsletter
        if ((role === Role.BIDDER || role === Role.BIDDER_READ_ONLY) && (this.props.location.state?.mail || mailEncoded)) {
            newUser.hasNewsletter = true;
        }

        const response = await postAsync("/register", newUser, false);
        let registrationComplete = false;
        if (response?.status === 200) {
            registrationComplete = true;
        } else if (response?.status === 400) {
            createErrorMessage(this.props.intl.formatMessage({id: "passwordreset.error.weak"}), this.props);
        } else if (response?.status === 406) {
            createErrorMessage(this.props.intl.formatMessage({id: "registration.mailNotAvailable.message"}), this.props);
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "registration.error.message"}), this.props);
        }

        this.setState({isRegistering: false, registrationComplete});
    }

    render() {
        let tooltipTitle = "";
        if (!this.validatePassword() && !this.validateEmailAddress()) {
            tooltipTitle = this.props.intl.formatMessage({id: "registration.missingData.tooltip"});
        } else if (!this.validatePassword()) {
            tooltipTitle = this.props.intl.formatMessage({id: "registration.password.invalid.tooltip"});
        } else if (!this.validateEmailAddress()) {
            tooltipTitle = this.props.intl.formatMessage({id: "registration.invalidMail.tooltip"});
        }

        return (
            <div>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showFromNewsletterDialog}
                    titleId="registration.fromNewsletter.dialog.title"
                    subtitleId="registration.fromNewsletter.dialog.subtitle"
                    actionId="commons.okay.button"
                    onAction={() => this.setState({showFromNewsletterDialog: false})}/>

                {this.state.registrationComplete
                    ? <TexisionContainer showBackButton="true" content={
                        <Grid container justifyContent="space-evenly" alignItems="center" spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant="h2">
                                    {this.props.intl.formatMessage({id : "registration.successDialog.title"})}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    {this.props.intl.formatMessage({id : "registration.successDialog.subtitle"})}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary"
                                        onClick={() => history.push({pathname: LOGIN_ROUTE, state: {mail: this.state.mail}})}>
                                    <FormattedMessage id={"commons.toLogin.button"}/>
                                </Button>
                            </Grid>
                        </Grid>
                    }/>

                    : <TexisionContainer showBackButton="true" content={

                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>

                        {/* title */}
                        <Grid item xs={12} style={{paddingBottom: "20px"}}>
                            <Typography variant="h2">
                                {this.props.intl.formatMessage({id: this.state.role === Role.PROJECT_ADMIN 
                                    ? "registration.projectAdmin.title" : "registration.bidder.title"})}
                            </Typography>
                        </Grid>

                        {/* subtitle for tender users */}
                        {this.state.role === Role.PROJECT_ADMIN && 

                            <Grid item xs={12}>
                                {this.props.intl.formatMessage({id: "registration.projectAdmin.subtitle2"})}
                                <Tooltip title={this.props.intl.formatMessage({id: "registration.projectAdmin.subtitle"})}>
                                    <div>
                                        <IconButton style={{color: texisionBlue}}>
                                            <InfoOutlinedIcon/>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </Grid> 
                        }

                        {/* email */}
                        <Grid item xs={2} xl={3}>
                            <Typography>{this.props.intl.formatMessage({id: "useradmin.email"}) + " *"}</Typography>
                        </Grid>

                        <Grid item xs={8} xl={6}>
                            <TextField 
                                required
                                id="email"
                                value={this.state.mail} 
                                variant="outlined"
                                style={{width: "100%", backgroundColor: this.validateEmailAddress() ? null : texisionWarningOrange}}
                                onChange={(e) => this.setState({mail: e.target.value})}/>
                        </Grid>

                        <Grid item xs={2} xl={3}/>

                        {/* password */}
                        <Grid item xs={2} xl={3}>
                            <Typography>{this.props.intl.formatMessage({id: "registration.password.title"}) + " *"}</Typography>
                        </Grid>

                        <Grid item xs={8} xl={6}>
                            <TextField 
                                required
                                id="password"
                                type={this.state.passwordVisible ? "text" : "password"}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (<InputAdornment position='end'>
                                        <IconButton
                                            onClick={() => this.setState({passwordVisible: !this.state.passwordVisible})}>
                                            {this.state.passwordVisible ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                    </InputAdornment>)
                                }}
                                style={{width: "100%", backgroundColor: this.validatePassword() ? null : texisionWarningOrange}}
                                onChange={(e) => this.setState({password: e.target.value})}/>
                        </Grid>

                        <Grid item xs={2} xl={3}/>

                        {/* password strength component */}
                        <Grid item xs={2} xl={3}/>

                        <PasswordStrengthIndicator password={this.state.password}/>

                        <Grid item xs={2} xl={3}/>

                        {/* hint */}
                        <Grid item xs={12} style={{paddingTop: 30}}>
                            <DialogContentText>
                                <FormattedMessage id="useradmin.createDialog.requiredNotice"/>
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <Tooltip title={tooltipTitle}>
                                <div>
                                    <Button 
                                        style={{marginTop: "20px", marginBottom: 30}} 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={() => this.createUser()} 
                                        disabled={!this.validateRequiredFields() || this.state.isRegistering}>
                                        <FormattedMessage id="registration.create.button"/>
                                    </Button>
                                </div>
                            </Tooltip>
                        </Grid>
                        
                    </Grid>
                }/>}
            </div>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(Registration)));
