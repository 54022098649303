import {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {GeneralContext} from "../contexts/GeneralContext";
import {CircularProgress} from "@mui/material";
import {putAsync} from "../../services/BackendService";
import {createErrorMessage, createSuccessMessage, isCockpit, isTender} from "../../util/Util";
import {withSnackbar} from "notistack";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import {
    APP_TYPE_KEY, BIDDER_DASHBOARD_ROUTE, COCKPIT_APP,
    DIALOG_TYPE_INFO,
    DIALOG_TYPE_WARNING,
    OFFER_APP, ORDER_APP, ORDER_DASHBOARD_ROUTE, TENDER_APP,
    DASHBOARD_ROUTE, PROJECT_STATUS_IS_PUBLISHED
} from "../../util/Constants";
import ConflictsSummary from "./ConflictsSummary";
import {getValidationId} from "../../util/ValidationUtil";
import {Button} from "@material-ui/core";
import history from "../navigation/shared/History";
import IconButton from "@material-ui/core/IconButton";
import ValidationIcon from "./ValidationIcon";
import {getActiveOperation} from "../../services/OperationService";
import {getActiveProject, isNoDataAllowedForProjectTask, updateValidationMap} from "../../services/ProjectService";

class ValidationButton extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showConflictsDialog: false,
            isLoading: false,
            showMarkCompletedDialog: false,
            validation: null,
            showIncompleteDataDialog: false,
            showSubTasksIncompleteDialog: false,
            errorTask: null,
            noData: false
        }
    }

    async componentDidMount() {
        await this.checkNoData();
    }

    async componentDidUpdate() {
        await this.checkNoData();
    }

    checkNoData = async() => {
        const noData = await isNoDataAllowedForProjectTask(this.props.projectTask, this.props.objectId);
        if (this.state.noData !== noData) {
            this.setState({noData});
        }
    }

    checkConflicts = async () => {
        this.setState({isLoading: true});
        const validationMap = this.context.appData.validationMap;
        const validation = {
            id: getValidationId(this.props.projectTask, this.props.objectId, validationMap),
            objectId: this.props.objectId,
            task: this.props.projectTask,
            complete: false
        };
        const response = await putAsync("/project/validation/" + this.props.projectId, validation);
        if (response?.status === 200) {
            if (response.data?.conflictTypes?.length) {
                this.props.onDialogOpen?.call();
                this.setState({showConflictsDialog: true, validation: response.data});
            } else {
                this.props.onDialogOpen?.call();
                this.setState({showMarkCompletedDialog: true, validation: null});
            }
        } else if ([401, 403].includes(response?.status)) {
            return await this.context.logout();
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "validation.error"}), this.props);
        }
        this.setState({isLoading: false});
    }

    updateProjectTask = async(newValue) => {
        this.setState({isLoading: true});
        const validation = {
            objectId: this.props.objectId,
            task: this.props.projectTask,
            complete: newValue
        };
        const response = await putAsync("/project/validation/" + this.props.projectId, validation);
        if (response?.status === 200 && response.data?.complete && newValue) {
            const successMessage = isTender() ? "validation.completed.tender.success" : "validation.completed.project.success";
            createSuccessMessage(this.props.intl.formatMessage({id: successMessage}), this.props);
            await updateValidationMap(this.context);
            if (!this.props.skipRedirect) {
                switch (localStorage.getItem(APP_TYPE_KEY)) {
                    case TENDER_APP:
                        this.context.setUnsavedChanges(false);
                        history.push(DASHBOARD_ROUTE);
                        break;
                    case COCKPIT_APP:
                        break;
                    case OFFER_APP:
                        this.context.setUnsavedChanges(false);
                        history.push(BIDDER_DASHBOARD_ROUTE);
                        break;
                    case ORDER_APP:
                        this.context.setUnsavedChanges(false);
                        history.push(ORDER_DASHBOARD_ROUTE);
                        break;
                    default:
                        break;
                }
            }

        } else if (response?.status === 200 && !response.data?.complete && newValue) {
            this.props.onDialogOpen?.call();
            this.setState({showSubTasksIncompleteDialog: true, errorTask: this.props.projectTask});
        } else if (response?.status === 200 && !response.data?.complete && !newValue) {
            createSuccessMessage(this.props.intl.formatMessage({id: "validation.incompleted.success." + this.props.projectTask}), this.props);
            await updateValidationMap(this.context);
        } else if ([401, 403].includes(response?.status)) {
            return await this.context.logout();
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "validation.error"}), this.props);
        }
        this.setState({isLoading: false});
    }

    onClick = async() => {
        if (!this.props.completed && this.props.incomplete && this.props.incompleteTextId) {
            this.props.onDialogOpen?.call();
            return this.setState({showIncompleteDataDialog: true});
        } else if (!this.props.completed) {
            return await this.checkConflicts();
        } else {
            return await this.updateProjectTask(false);
        }
    }

    onConflictsDialogClose = async() => {
        this.props.onDialogClose?.call();
        this.setState({showConflictsDialog: false, validation: null, isLoading: true});
        await updateValidationMap(this.context);
        this.setState({isLoading: false});
    }

    onSubTasksIncompleteDialogClose = () => {
        this.props.onDialogClose?.call();
        this.setState({showSubTasksIncompleteDialog: false, errorTask: null});
    }

    onIncompleteDialogClose = () => {
        this.props.onDialogClose?.call();
        this.setState({showIncompleteDataDialog: false});
    }

    onComplete = async() => {
        this.props.onDialogClose?.call();
        this.setState({showMarkCompletedDialog: false});
        await this.updateProjectTask(true);
    }

    onContinue = () => {
        this.props.onDialogClose?.call();
        this.setState({showMarkCompletedDialog: false});
    }

    render() {
        if ((isCockpit() && !getActiveOperation(this.context)?.editable)
            || (isTender() && getActiveProject(this.context)?.status === PROJECT_STATUS_IS_PUBLISHED)) {
            return null;
        }
        const validationMarkSubtitleId = isTender() ? "validation.mark.dialog.tender.subtitle" : "validation.mark.dialog.project.subtitle";
        const validationConflictsSubtitleId = isTender() ? "validation.conflicts.dialog.tender.subtitle" : "validation.conflicts.dialog.project.subtitle";
        let buttonId;
        if (this.props.completed) {
            buttonId = this.props.completedButtonText ?? "validation.completed.button";
        } else if (this.state.noData === true) {
            buttonId = "validation.nodata.button";
        } else {
            buttonId = this.props.incompletedButtonText ?? "validation.incompleted.button";
        }
        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showConflictsDialog}
                    titleId="validation.conflicts.dialog.title"
                    subtitleId={validationConflictsSubtitleId}
                    content={<ConflictsSummary
                        objectId={this.props.objectId}
                        validationList={[this.state.validation]}/>}
                    cancelId="commons.cancel.button"
                    onCancel={() => this.onConflictsDialogClose()}/>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showSubTasksIncompleteDialog}
                    titleId="validation.subTasksIncomplete.dialog.title"
                    subtitleId={this.state.errorTask ? ("validation.subTasksIncomplete." + this.state.errorTask + ".dialog.subtitle") : null}
                    cancelId="commons.cancel.button"
                    onCancel={() => this.onSubTasksIncompleteDialogClose()}/>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showIncompleteDataDialog}
                    titleId="validation.conflicts.dialog.title"
                    subtitleId={this.props.incompleteTextId}
                    cancelId="commons.cancel.button"
                    onCancel={() => this.onIncompleteDialogClose()}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showMarkCompletedDialog}
                    titleId="validation.mark.dialog.title"
                    subtitleId={validationMarkSubtitleId}
                    actionId={"validation.mark.complete.button"}
                    actionDisabled={this.state.isLoading}
                    onAction={() => this.onComplete()}
                    cancelId={"validation.continue.button"}
                    onCancel={() => this.onContinue()} />

                {this.props.iconButton

                    ? <IconButton
                        onClick={() => this.onClick()}
                        disabled={this.state.isLoading || this.props.disabled}>
                        <ValidationIcon complete={this.props.completed} size={24}/>
                    </IconButton>

                    : <Button
                        onClick={() => this.onClick()}
                        disabled={this.state.isLoading || this.props.disabled}
                        variant="contained"
                        color="primary"
                        style={{whiteSpace: "nowrap"}}>
                        {this.state.isLoading
                            ? <CircularProgress size={20} style={{color: "white"}}/>
                            : <FormattedMessage id={buttonId}/>}
                    </Button>
                }
            </>
        );
    }
}

export default withSnackbar(injectIntl(ValidationButton));
