import React, {Component} from 'react';
import {primaryColor} from "../../../../util/ColorTheme";
import Grid from "@material-ui/core/Grid";
import {Box, Button, Select, Tab, Tabs} from "@material-ui/core";
import CategorySelection from "./CategorySelection";
import {FormattedMessage, injectIntl} from "react-intl";
import Popover from "@material-ui/core/Popover";
import CategoryStatisticsLineChart from "./CategoryStatisticsLineChart";
import CategoryStatisticsBarChart from "./CategoryStatisticsBarChart";
import CategoryStatisticsTable from "./CategoryStatisticsTable";
import ChartTypeSelection from "../ChartTypeSelection";

class CategoryStatisticsChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            showPredictionInfo: false,
            selectedTab: "price",
            showNet: false,
            allSelected: false,
            workWearSelected: false,
            flatLinenSelected: false,
            customerArticlesSelected: false,
            residentsLaundrySelected: false,
            operatingResourcesSelected: false,
            chartType: "line",
            accumulated: false
        }
    }

    getChartProps = () => {
        const {
            selectedTab, allSelected, workWearSelected, flatLinenSelected, customerArticlesSelected,
            residentsLaundrySelected, operatingResourcesSelected, showNet
        } = this.state;
        return {
            accumulated: this.state.accumulated,
            currency: this.props.currency,
            maxDate: this.props.maxDate,
            minDate: this.props.minDate,
            selectedTab: selectedTab,
            showNet: showNet,
            priceOfferMap: this.props.priceOfferMap,
            allSelected: allSelected,
            workWearSelected: workWearSelected,
            flatLinenSelected: flatLinenSelected,
            customerArticlesSelected: customerArticlesSelected,
            residentsLaundrySelected: residentsLaundrySelected,
            operatingResourcesSelected: operatingResourcesSelected,
        };
    }

    render() {
        const {
            selectedTab, allSelected, workWearSelected, flatLinenSelected, customerArticlesSelected,
            residentsLaundrySelected, operatingResourcesSelected, showNet
        } = this.state;

        let chart;
        if (this.state.chartType === "line") {
            chart = <CategoryStatisticsLineChart {...this.getChartProps()}/>;
        } else if (this.state.chartType === "bar") {
            chart = <CategoryStatisticsBarChart {...this.getChartProps()}/>;
        } else if (this.state.chartType === "table") {
            chart = <CategoryStatisticsTable {...this.getChartProps()}/>
        }

        return (
            <>
                <Popover
                    key="source-popover"
                    open={this.state.showPredictionInfo}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({showPredictionInfo: false, anchorEl: null})}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    transformOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <div style={{padding: 30, width: 400}}>
                        <FormattedMessage id="cockpit.statistics.prediction.info.text"/>
                    </div>
                </Popover>

                <Grid container>
                    <Grid item xs>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs
                                TabIndicatorProps={{style: {backgroundColor: primaryColor}}}
                                value={selectedTab}
                                onChange={(_, newTab) => this.setState({selectedTab: newTab})}
                                style={{width: "100%"}}>
                                <Tab fullWidth value="price" label={this.props.intl.formatMessage({id: "priceSheet.column.price"})}/>
                                <Tab fullWidth value="amount" label={this.props.intl.formatMessage({id: "priceSheet.column.amount"})}/>
                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid item style={{marginTop: 10, marginRight: 10}}>
                        <Button color="primary" variant="outlined" onClick={(e) => this.setState({showPredictionInfo: true, anchorEl: e.target})}>
                            <FormattedMessage id="cockpit.statistics.prediction.info.button"/>
                        </Button>
                    </Grid>
                </Grid>

                <div style={{width: "90%", minHeight: 200, padding: 40, display: "flex", justifyContent: "center", alignItems: "flex-start"}}>

                    <Grid container spacing={4}>

                        {this.state.selectedTab === "price" && <Grid item xs={2}>
                            <Select
                                native
                                value={this.state.showNet ? "net" : "gross"}
                                label={this.props.intl.formatMessage({id: this.state.showNet ? "cockpit.statistics.showNet" : "cockpit.statistics.showGross"})}
                                onChange={(e) => this.setState({showNet: e.target.value === "net"})}
                                inputProps={{name: 'net-gross-select', id: 'nett-gross-native-simple'}}>
                                <option
                                    key={"gross-option"}
                                    value={"gross"}>
                                    {this.props.intl.formatMessage({id: "cockpit.statistics.showGross"})}
                                </option>
                                <option
                                    key={"net-option"}
                                    value={"net"}>
                                    {this.props.intl.formatMessage({id: "cockpit.statistics.showNet"})}
                                </option>
                            </Select>
                        </Grid>}

                        <Grid item xs={10}>
                            <CategorySelection
                                selectedTab={selectedTab}
                                allSelected={allSelected}
                                workWearSelected={workWearSelected}
                                flatLinenSelected={flatLinenSelected}
                                customerArticlesSelected={customerArticlesSelected}
                                residentsLaundrySelected={residentsLaundrySelected}
                                operatingResourcesSelected={operatingResourcesSelected}
                                onAllChanged={(newValue) => this.setState({allSelected: newValue})}
                                onWorkWearChanged={(newValue) => this.setState({workWearSelected: newValue})}
                                onFlatLinenChanged={(newValue) => this.setState({flatLinenSelected: newValue})}
                                onCustomerArticlesChanged={(newValue) => this.setState({customerArticlesSelected: newValue})}
                                onResidentsLaundryChanged={(newValue) => this.setState({residentsLaundrySelected: newValue})}
                                onOperatingResourcesChanged={(newValue) => this.setState({operatingResourcesSelected: newValue})}
                                minDate={this.props.minDate}
                                showNet={showNet}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <ChartTypeSelection
                                accumulated={this.state.accumulated}
                                onAccumulatedChange={(accumulated) => this.setState({accumulated})}
                                chartType={this.state.chartType}
                                onChartTypeChange={(chartType) => this.setState({chartType})}
                            />
                        </Grid>

                        <Grid item xs={12}>

                            {!allSelected && !workWearSelected && !flatLinenSelected && !customerArticlesSelected && !residentsLaundrySelected
                            && !operatingResourcesSelected

                                ? (
                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        {this.props.intl.formatMessage({id: "cockpit.statistics.categories.nothingSelected"})}
                                    </div>
                                )

                                : chart
                            }
                        </Grid>

                    </Grid>

                </div>
            </>
        );
    }
}

export default injectIntl(CategoryStatisticsChart);
