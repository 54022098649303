import {Component} from "react";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {ResponsiveContainer} from "recharts";
import {injectIntl} from "react-intl";
import { DataGrid } from '@mui/x-data-grid';
import {accumulateData, getLegendLabel, getNodeColor} from "../../../../services/StatisticsService";
import {InvoiceStatisticsBarData} from "./InvoiceStatisticsBarData";
import "../../../../css/InvoiceStatistics.css";
import {formatPrice} from "../../../../util/Util";
import CircleIcon from '@mui/icons-material/Circle';

class InvoiceStatisticsTable extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            activeInvoiceId: null,
            activeKey: null
        }
    }

    handleCellClick = (params) => {
        let invoiceId = parseInt(params.field);
        let key = params.row.start.key;
        this.props.onItemSelected(key, invoiceId);
        this.setState({activeKey: key, activeInvoiceId: invoiceId});
    };

    buildColumns = () => {
        const {selectedTab, sortedInvoices, getXAxisTickLabel} = this.props;
        const itemLabel = this.props.intl.formatMessage({ id : 'cockpit.statistics.invoice.item.label'});
        const columns = [
            {
                field: 'start',
                headerName: '#',
                headerClassName: "statistic-table-header",
                width: 50,
                align: "center",
                sortable: false,
                renderCell: (params) => {
                    const key = params.value.key;
                    const color = params.value.color;
                    return (
                        <div
                            key={'table-key-cell-'+key}
                            style={{
                                color: color,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <CircleIcon/>
                        </div>
                    );
                }
            },
            {
                field: 'name',
                headerName: itemLabel,
                headerClassName: "statistic-table-header",
                flex: 2
            }
        ];
        sortedInvoices.forEach(invoice => {
            columns.push({
                field: ""+invoice.id,
                headerName: getXAxisTickLabel(invoice.id),
                headerClassName: "statistic-table-header",
                align: "right",
                flex: 1,
                valueFormatter: (params) => {
                    return selectedTab.includes("price")
                        ? formatPrice(params.value)
                        : params.value.toFixed(0);
                },
            })
        })

        return columns;
    }

    buildRows = () => {
        const {
            accumulated, sortedInvoices, statisticItemMap, selectedNodes, selectedTab
        } = this.props;

        if (selectedNodes.length === 0 || !sortedInvoices || sortedInvoices.length === 0) {
            return [];
        }

        let chartData;
        const [data, dataKeys] = InvoiceStatisticsBarData({
            context: this.context, sortedInvoices, statisticItemMap, selectedNodes, selectedTab
        });

        if (accumulated) {
            chartData = accumulateData(data, dataKeys, "invoiceId");
        } else {
            chartData = data;
        }

        let rows = [];
        let index = 1;
        dataKeys.forEach((key) => {

            let name = getLegendLabel(this.context, selectedNodes, key)
            let color = getNodeColor(selectedNodes, key)
            let row = {
                id: index++,
                start: {key: key, color: color},
                name: name
            };
            chartData.forEach(data => {
                row[""+data.invoiceId] = data[key]
            });
            rows.push(row);
        })
        return rows;
    }

    render() {
        return (
            <ResponsiveContainer debounce={300} width="100%" aspect={2.6} minWidth={900}>
                <DataGrid className={"statistics-data-grid"}
                          autoHeight
                          hideFooter={true}
                          rows={this.buildRows()}
                          columns={this.buildColumns()}
                          onCellClick={this.handleCellClick}
                          showCellRightBorder={true}
                          disableColumnMenu={true}
                          getRowClassName={(params) =>
                              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                          }
                />
            </ResponsiveContainer>);
    }
}

export default injectIntl(InvoiceStatisticsTable);