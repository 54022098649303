import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import { getAsync } from '../../services/BackendService';
import {isAdmin, isOfferUser, isProjectAdmin} from '../../services/UserService';

import '../apps/App.css';
import '../../css/ProjectDashboard.css';
import {Button, Card, CardContent, Grid, Typography} from '@material-ui/core';
import ProjectListItem from './ProjectListItem';

import {withSnackbar} from 'notistack';
import {GeneralContext} from "../contexts/GeneralContext";
import ProjectCreateOrUpdateDialog from './ProjectCreateOrUpdateDialog';
import ProjectSummary from './ProjectSummary';
import ProjectDeleteDialog from './ProjectDeleteDialog';
import {
    DIALOG_TYPE_WARNING,
    PROJECT_STATUS_IN_EDITING,
    PROJECT_STATUS_IN_POST_PROCESSING,
    PROJECT_STATUS_IN_PROGRESS
} from "../../util/Constants";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import ProjectCreateWithInvoicesDialog from "./projectCreateWithInvoices/ProjectCreateWithInvoicesDialog";
import {isTender} from "../../util/Util";
import {loadProfiles} from "../../services/ProfileService";


class ProjectOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);

        this.state = {
            projectToEdit: null,
            allProfiles: [],
            showCreateOrUpdateDialog: false,
            showCreateWithInvoicesDialog: false,
            showDeleteProjectDialog: false,
            showProjectPublishedDialog: false,
            showProjectUnDeletableDialog: false,
            projectToDelete: null,
            // operation attributes
            isPrivate: true,
            profileIds: [],
            operationIdToPaymentsMap: new Map()
        }
    }

    async componentDidMount() {
        await Promise.all([this.loadPayments(), this.loadProfiles()]);
    }

    getProjects = () => {
        if (isTender()) {
            return this.context.appData.allProjects;
        } else {
            return this.context.appData.operations?.map(o => o.workingProject ? o.workingProject : o.activeProject);
        }
    }

    loadProfiles = async() => {
        let allProfiles = await loadProfiles(this.context);
        this.setState({allProfiles});
    }

    loadPayments = async() => {
        let operationIdToPaymentsMap = new Map();
        const allOperations = this.context.appData.operations;
        if (allOperations?.length) {
            for (let operationId of allOperations.map(o => o.id)) {
                const response = await getAsync("/payment/" + operationId);
                if (response?.status === 200 && response.data) {
                    operationIdToPaymentsMap.set(operationId, response.data);
                }
            }
        }
        this.setState({operationIdToPaymentsMap});
    }

    showCreateDialog = () => {
        this.setState({
            operation: null,
            projectToEdit: null, 
            showCreateOrUpdateDialog: true
        });
    }

    showCreateWithInvoicesDialog = () => {
        this.setState({
           operation: null,
           projectToEdit: null,
           showCreateWithInvoicesDialog: true
        });
    }

    showEditDialog = (project) => {
        const operation = this.context.appData.operations?.find(o => o.activeProject?.id === project.id || o.workingProject?.id === project.id);
        this.setState({
            operation: operation,
            projectToEdit: project, 
            showCreateOrUpdateDialog: true
        });
    }
    
    closeCreateOrEditDialog = () => {
        this.setState({
            operation: null,
            projectToEdit: null, 
            showCreateOrUpdateDialog: false,
        });
    }

    handleDelete = (project) => {
        const operation = this.context.appData.operations?.find(o => o.activeProject?.id === project.id || o.workingProject?.id === project.id);
        const isDirectCockpitProject = !isTender() && !isOfferUser(operation?.bidder);
        if ((isTender() && [PROJECT_STATUS_IN_PROGRESS, PROJECT_STATUS_IN_EDITING].includes(project?.status))
            || (isDirectCockpitProject && project?.status === PROJECT_STATUS_IN_POST_PROCESSING)) {
            this.setState({projectToDelete: project, showDeleteProjectDialog: true})
        } else if (isTender()) {
            this.setState({showProjectPublishedDialog: true});
        } else {
            this.setState({showProjectUnDeletableDialog: true});
        }
    }

    render() {
        const user = this.context.currentUser;
        const createText = isTender() ? "tender.create.text" : "project.create.text";
        const createButtonText = isTender() ? "tender.create.button.text" : "project.create.button.text";
        const allTitle =  isTender() ? "tender.title.allProjects" : "project.title.allProjects";

        return (
            <>

                <ProjectCreateOrUpdateDialog
                    key={"create-operation-"+this.state.operation?.id}
                    operation={this.state.operation} 
                    showDialog={this.state.showCreateOrUpdateDialog}
                    allProfiles={this.state.allProfiles}
                    project={this.state.projectToEdit} 
                    profileIds={this.state.profileIds} 
                    closeDialog={() => this.closeCreateOrEditDialog()}/>

                <ProjectDeleteDialog
                    key={"delete-operation-"+this.state.operation?.id}
                    showDeleteProjectDialog={this.state.showDeleteProjectDialog}
                    projectToDelete={this.state.projectToDelete}
                    hasLicenses={this.state.operationIdToPaymentsMap.get(this.state.projectToDelete?.operationId)?.length}
                    closeDeleteDialog={() => this.setState({showDeleteProjectDialog: false, projectToDelete: null})}/>

                <ProjectCreateWithInvoicesDialog
                    allProfiles={this.state.allProfiles}
                    open={this.state.showCreateWithInvoicesDialog}
                    onCancel={() => this.setState({showCreateWithInvoicesDialog: false})}/>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showProjectPublishedDialog}
                    cancelId="commons.close.label"
                    onCancel={() => this.setState({showProjectPublishedDialog: false})}
                    titleId="tender.overview.published.title"
                    subtitleId="tender.overview.published.subtitle"/>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showProjectUnDeletableDialog}
                    cancelId="commons.close.label"
                    onCancel={() => this.setState({showProjectUnDeletableDialog: false})}
                    titleId="project.overview.unDeletable.title"
                    subtitleId="project.overview.unDeletable.subtitle"/>
               
                <ProjectSummary/>

                <Card style={{marginBottom: 30}}>
                    <CardContent style={{padding: '3rem', textAlign: 'center'}}>
                        <Grid container justifyContent="space-evenly" spacing={6}>
                            <Grid item>
                                <div>
                                    <FormattedMessage id={createText}/>
                                </div>
                                <Button className="mt-5"
                                        color="primary"
                                        variant="contained"
                                        disabled={!isAdmin(user) && !isProjectAdmin(user)}
                                        onClick={() => this.showCreateDialog()}>
                                    <FormattedMessage id={createButtonText}/>
                                </Button>
                            </Grid>
                            {!isTender() && <Grid item>
                                <div>
                                    <FormattedMessage id="project.create.withInvoices.title"/>
                                </div>
                                <Button className="mt-5"
                                        color="primary"
                                        variant="contained"
                                        disabled={!isAdmin(user) && !isProjectAdmin(user)}
                                        onClick={() => this.showCreateWithInvoicesDialog()}>
                                    <FormattedMessage id="project.create.withInvoices.button"/>
                                </Button>
                            </Grid>}
                        </Grid>
                    </CardContent>
                </Card>

                {!!this.getProjects()?.length &&
                    <Typography variant="h2" className="mb-5">
                        <FormattedMessage id={allTitle}/>
                    </Typography>
                }
                
                {this.getProjects()?.sort((a,b) => a.name.localeCompare(b.name))?.map(project => {

                    const operation = this.context.appData.operations?.find(o => o.activeProject?.id === project.id || o.workingProject?.id === project.id);

                    return <Card key={"project-" + project.name + "-id-" + project.id} className="mb-5">
                        <CardContent style={{padding: 0}}>
                            <ProjectListItem
                                operation={operation}
                                project={project}
                                allProfiles={this.state.allProfiles}
                                handleEdit={(project) => this.showEditDialog(project)}
                                handleDelete={(project) => this.handleDelete(project)}/>
                        </CardContent>
                    </Card>

                })}

            </>
        );
    }
}

export default injectIntl(withSnackbar(ProjectOverview));
