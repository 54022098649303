import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    Checkbox, FormControlLabel,
    Grid,
    IconButton,
    InputLabel, Radio,
    Tooltip,
    Typography
} from "@material-ui/core";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {getAsyncCatch, postAsyncCatch} from "../../../services/BackendService";

import {withSnackbar} from 'notistack';
import {
    ARTICLE_CATEGORIES,
    DIALOG_TYPE_INFO,
    EQUIPMENT_TYPE_AREA,
    EQUIPMENT_TYPE_PROFESSIONAL_GROUP,
    RENTAL_LINEN_ROUTE
} from "../../../util/Constants";
import {Link, Redirect, withRouter} from "react-router-dom";
import '../../../css/RentalLinen.css';
import {GeneralContext} from "../../contexts/GeneralContext";
import MaterialTable from "material-table";
import {renderImage} from "../../../util/TableUtil";
import {bodyBackgroundColor, texisionFontColorDark} from "../../../util/ColorTheme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArticleDetail from "../../share/ArticleDetail";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import {createErrorMessage} from "../../../util/Util";
import FilterCategoriesPreview from "../../administration/articleOverview/FilterCategoriesPreview";
import {wrapOptionsWithCategories} from "../../../util/ArticleHelper";
import {FaceRetouchingNaturalOutlined} from "@mui/icons-material";
import {getAreaById, updateArea} from "../../../services/AreaService";
import {getProfessionalGroupById, updateProfessionalGroup} from "../../../services/ProfessionalGroupService";
import ArticleConfigurationSelector from "./ArticleConfigurationSelector";
import {createOrGetArticle} from "../../../services/ArticleConfigurationService";


class RentalLinenAssignment extends Component {
    
    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            keyIndex: 0,
            area: this.props.location.state?.area,
            professionalGroup: this.props.location.state?.professionalGroup,
            assignedArticles: this.props.location.state?.assignedArticles ?? [],
            assignedArticleIds: [],
            isSaved: false,
            isLoading: false,
            savedFilterCategories: [],
            newFilterCategories: [],
            articleForDetailView: null,
            selectedArticle: null,
        }
        if (this.state.area) {
            this.state.assignedArticleIds = [...this.state.area.articleIds];
        } else if (this.state.professionalGroup) {
            this.state.assignedArticleIds = [...this.state.professionalGroup.articleIds];
        }
    }

    componentDidUpdate() {
        this.context.setUnsavedChanges(this.hasUnsavedChanges() && !this.state.isSaved);
    }

    reloadArea = async() => {
        const area = await getAreaById(this.context, this.props, this.state.area.id);
        this.setState({area, assignedArticleIds: area?.articleIds ?? []});
    }

    reloadProfessionalGroup = async() => {
        const professionalGroup = await getProfessionalGroupById(this.context, this.props, this.state.professionalGroup.id);
        this.setState({professionalGroup, assignedArticleIds: professionalGroup?.articleIds ?? []});
    }

    reloadAssignedArticles = async() => {
        const data = await getAsyncCatch(this.context, "/assortment/" + this.context.appData.activeUnitId, this.props);
        if (data) {
            let assortmentArticles = data.articles;
            let articles = await postAsyncCatch(this.context, "/articles", {ids: assortmentArticles.map(a => a.articleId)}, this.props, true);
            if (articles) {
                for (let article of articles) {
                    article.sources = assortmentArticles.find(a => a.articleId === article.id)?.sources;
                }
                if (this.state.area) {
                    articles = articles.filter(a => a.sources?.find(s => s.sourceId === this.state.area.id && s.type === EQUIPMENT_TYPE_AREA));
                } else if (this.state.professionalGroup) {
                    articles = articles.filter(a => a.sources?.find(s => s.sourceId === this.state.professionalGroup.id && s.type === EQUIPMENT_TYPE_PROFESSIONAL_GROUP));
                } else {
                    articles = [];
                }
                this.setState({assignedArticles: articles});
            }
        }
    }

    hasUnsavedChanges = () => {
        let originalList = [];
        if (this.state.area) {
            originalList = [...this.state.area.articleIds];
        } else if (this.state.professionalGroup) {
            originalList = [...this.state.professionalGroup.articleIds];
        }
        if (!this.state.assignedArticleIds || !originalList) {
            return false;
        } else {
            return this.state.assignedArticleIds.sort().join(',') !== originalList.sort().join(',');
        }
    }

    saveChanges = () => {
        this.context.setUnsavedChanges(false);
        this.setState({isSaved: true});
    }

    getFilterOptionsList = () => {
        if (!this.state.savedFilterCategories?.length) {
            return [];
        }
        let optionsList = [];
        for (let fc of this.state.savedFilterCategories) {
            for (let option of fc.filterOptions) {
                if (!optionsList.find(o => o.filterCategoryId === fc.id && o.name === option.name)) {
                    optionsList.push(option);
                }
            }
        }
        return optionsList;
    }

    createOrGetArticle = async() => {
        const articleVo = await createOrGetArticle(
            this.context, this.props, this.state.selectedArticleConfiguration, this.getFilterOptionsList());
        if (articleVo) {
            const articleId = articleVo.id;
            if (this.state.assignedArticleIds?.includes(articleId)) {
                createErrorMessage(this.props.intl.formatMessage({id: "rentalLinen.assignment.duplicateArticle.hint"}), this.props);
            } else {
                await this.setState({assignedArticleIds: [...this.state.assignedArticleIds, articleId]});
                await this.assignArticle();
                this.setState({keyIndex: this.state.keyIndex + 1, selectedArticleConfiguration: null});
            }
        }
    }

    assignArticle = async() => {
        this.setState({isLoading: true});
        if (this.state.area) {
            await this.assignToArea(this.state.assignedArticleIds);
        } else if (this.state.professionalGroup) {
            await this.assignToProfessionalGroup(this.state.assignedArticleIds);
        }
        this.setState({isLoading: false});
    }

    removeArticle = async(articleId) => {
        this.setState({isLoading: true});
        if (this.state.area) {
            await this.assignToArea(this.state.assignedArticleIds.filter(id => id !== articleId));
        } else if (this.state.professionalGroup) {
            await this.assignToProfessionalGroup(this.state.assignedArticleIds.filter(id => id !== articleId));
        }
        this.setState({isLoading: false});
    }

    assignToArea = async(assignedArticleIds) => {
        const area = this.state.area;
        const areaToUpdate = {
            id: area.id,
            businessUnitId: area.businessUnitId,
            name: area.name,
            articleIds: assignedArticleIds,
            version: area.version
        }
        const updatedArea = await updateArea(this.context, this.props, areaToUpdate);
        this.saveChanges();
        if (updatedArea) {
            this.setState({category: "", subCategory: "", articleCategory: "", newFilterCategories: [], savedFilterCategories: []});
            await Promise.all([
                this.reloadArea(),
                this.reloadAssignedArticles()
            ]);
        }
    }

    assignToProfessionalGroup = async(assignedArticleIds) => {
        const professionalGroup = this.state.professionalGroup;
        const professionalGroupToUpdate = {
            id: professionalGroup.id,
            businessUnitId: professionalGroup.businessUnitId,
            name: professionalGroup.name,
            type: professionalGroup.type,
            gender: professionalGroup.gender,
            articleIds: assignedArticleIds,
            version: professionalGroup.version
        }
        const updatedProfessionalGroup = await updateProfessionalGroup(this.context, this.props, professionalGroupToUpdate);
        this.saveChanges();
        if (updatedProfessionalGroup) {
            this.setState({category: "", subCategory: "", articleCategory: "", newFilterCategories: [], savedFilterCategories: []});
            await Promise.all([
                this.reloadProfessionalGroup(),
                this.reloadAssignedArticles()
            ]);
        }
    }

    title = () => {
        if (this.state.area) {
            return this.props.intl.formatMessage({id: "area.assignment.title"}) + ": " + this.state.area.name;
        } else if (this.state.professionalGroup) {
            return this.props.intl.formatMessage({id: "professionalGroup.assignment.title"}) + ": " + this.state.professionalGroup.name;
        } else {
            return "";
        }
    }

    subtitle = () => {
        if (this.state.area) {
            return this.props.intl.formatMessage({id: "area.assignment.subtitle"});
        } else if (this.state.professionalGroup) {
            return this.props.intl.formatMessage({id: "professionalGroup.assignment.subtitle"});
        } else {
            return "";
        }
    }

    showArticleDetails = (article) => {
        let articleForDetailView = {...article};
        articleForDetailView.filterCategories = wrapOptionsWithCategories(article, this.props.location.state?.articleConfigurations);
        this.setState({articleForDetailView});
    }

    mayAssignArticle = () => {
        return !!this.state.selectedArticleConfiguration &&
            this.state.savedFilterCategories.every((fc) => fc.isMultipleChoice || fc.filterOptions.length > 0)
    }

    getNonEmptyFilterCategories = () => {
        return this.state.savedFilterCategories?.filter(fc => fc.filterOptions?.find(o => o.name));
    }

    findAssignedArticle = (category, subCategory, articleCategory) => {
        return this.state.assignedArticles?.find(a =>
            a.category === category &&
            a.subcategory === subCategory &&
            a.articleCategory === articleCategory);
    }

    handleFilterCategoriesChanged = () => {
        this.setState({showFilterDialog: false, savedFilterCategories: JSON.parse(JSON.stringify(this.state.newFilterCategories))});
    }

    handleFilterCategoriesCancelled = () => {
        this.setState({showFilterDialog: false, newFilterCategories: JSON.parse(JSON.stringify(this.state.savedFilterCategories))});
    }

    handleFilterCategoriesOpen = () => {
        this.setState({showFilterDialog: true, newFilterCategories: JSON.parse(JSON.stringify(this.state.savedFilterCategories))});
    }

    handleOptionChanged = (option, checked, isMultipleChoice) => {
        let filterCategory = this.state.newFilterCategories.find(nfc => nfc.id === option.filterCategoryId);
        if (!filterCategory?.filterOptions) {
            return;
        }
        if (checked && isMultipleChoice) {
            filterCategory.filterOptions = [...filterCategory.filterOptions, option];
        } else if (checked && !isMultipleChoice) {
            filterCategory.filterOptions = [option];
        } else {
            filterCategory.filterOptions = filterCategory.filterOptions.filter(o => o.id !== option.id);
        }
        const newFilterCategories = this.state.newFilterCategories.map(nfc => nfc.id === option.filterCategoryId ? filterCategory : nfc);
        this.setState({newFilterCategories});
    }

    resetFilterOptions = () => {
        let filterCategories = this.initializeFilterCategories(this.state.selectedArticleConfiguration);
        this.setState({newFilterCategories: filterCategories});
    }

    resetFilterOptionsButtonDisabled = () => {
        return !this.state.newFilterCategories?.find(nfc => nfc.filterOptions?.length);
    }

    handleDescriptionChanged = async(articleConfiguration) => {
        let initializedFilterCategories = this.initializeFilterCategories(articleConfiguration);
        this.setState({savedFilterCategories: initializedFilterCategories, newFilterCategories: initializedFilterCategories});
    }

    initializeFilterCategories = (articleConfiguration) => {
        let initializedFilterCategories = [];
        if (articleConfiguration?.filterCategories?.length) {
            for (let fc of articleConfiguration.filterCategories) {
                let fcWithEmptyOptions = JSON.parse(JSON.stringify(fc));
                // pre-selection for categories with only one option
                if (fcWithEmptyOptions.filterOptions.length > 1) {
                    // clear initial selection for categories with two or more options
                    fcWithEmptyOptions.filterOptions = [];
                }
                initializedFilterCategories.push(fcWithEmptyOptions);
            }
        }
        return initializedFilterCategories;
    }

    configurator = () => {
        const type = this.state.area ? "area" : "professionalGroup";
        const articleConfiguration = this.state.selectedArticleConfiguration;
        return (
          <>

              <Typography variant="h2">
                  <FormattedMessage id={"rentalLinen.assignment.configurator.title"}/>
              </Typography>

              <Typography variant="subtitle2">
                  <FormattedMessage id={"rentalLinen.assignment.configurator." + type + ".subtitle"}/>
              </Typography>
              <Card>
                 <ArticleConfigurationSelector
                     key={"article-configurator-selector-" + this.state.keyIndex}
                     articleConfigurations={this.props.location.state.articleConfigurations}
                     onSelect={(articleConfiguration) => {
                         this.setState({selectedArticleConfiguration: articleConfiguration});
                         this.handleDescriptionChanged(articleConfiguration);
                     }
                 }
                 />

                  {articleConfiguration && <Grid item style={{width: 200, marginTop: 20}}>
                      {renderImage(articleConfiguration, () => this.setState({articleForDetailView: articleConfiguration}))}
                  </Grid>}

                  {articleConfiguration && <Grid item xs={12}>
                      <InputLabel
                          htmlFor="filter-categories-native-simple"
                          style={{marginTop: "20px", marginBottom: 5}}>
                          <FormattedMessage id="rentalLinen.assignment.filter.title"/> *
                      </InputLabel>
                  </Grid>}

                  {articleConfiguration && !!this.getNonEmptyFilterCategories()?.length && <Grid item xs={12}>
                      <Grid container spacing={2}>
                          {this.getNonEmptyFilterCategories().map(fc => <Grid item style={{width: 300, marginBottom: 25}}>
                              <FormattedMessage id={"constants.FilterCategory." + fc.filterCategoryType}/>
                              <Grid container style={{marginTop: 10}} spacing={1}>
                                  {fc.filterOptions.map(o => <Grid item>
                                      <div style={{borderRadius: 20, border: "1px solid #b3c0c7", backgroundColor: "#f3f6f8",
                                          paddingLeft: 20, paddingRight: 20, paddingTop: 2, paddingBottom: 2}}>
                                          {o.name}
                                      </div>
                                  </Grid>)}
                              </Grid>
                          </Grid>)}
                      </Grid>
                  </Grid>}

                  {articleConfiguration && <Grid item xs={12} style={{marginTop: 15}}>
                      <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => this.handleFilterCategoriesOpen()}
                          disabled={this.props.readOnly}>
                          <FormattedMessage id={"rentalLinen.assignment.addFilters.button"}/>
                      </Button>
                  </Grid>}

                  <Grid item xs={12} style={{marginTop: 30}}>
                      <Button
                          variant="contained"
                          color="primary"
                          disabled={!this.mayAssignArticle() || this.state.isLoading || this.props.readOnly}
                          onClick={() => this.createOrGetArticle()}>
                          <FormattedMessage id="rentalLinen.assignment.assign.button"/>
                      </Button>
                  </Grid>
              </Card>
          </>
        );
    }

    deleteButton = (article) => {
        return (
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <IconButton onClick={() => this.removeArticle(article.id)} disabled={this.state.isLoading || this.props.readOnly}>
                    <DeleteIcon/>
                </IconButton>
            </div>
        );
    }

    filterOptions = (article) => {
        let filterCategories = [];
        for (let filterCategory of this.props.location?.state?.articleConfigurations
            ?.find(ac => ac.id === article.articleConfigurationId)?.filterCategories ?? []) {
            const options = article.filterOptions?.filter(o => o.filterCategoryId === filterCategory.id);
            if (options?.length) {
                filterCategories.push({
                   id: filterCategory.id,
                   articleConfigurationId: article.articleConfigurationId,
                   filterCategoryType: filterCategory.filterCategoryType,
                   filterOptions: options
                });
            }
        }
        return (
            <FilterCategoriesPreview filterCategories={filterCategories} articleConfigurationId={article.articleConfigurationId}/>
        );
    }

    rentalLinenTable = (category) => {
        let data = this.state.assignedArticles.filter(r => r.category === category)
            .sort((a,b) => this.props.intl.formatMessage({id: "constants.SubCategory." + a.subcategory})
                .localeCompare(this.props.intl.formatMessage({id: "constants.SubCategory." + b.subcategory})));
        return (
            <Grid item xs={12}>
                <MaterialTable
                    className="rentalLinenTable"
                    columns={[
                        {title: this.props.intl.formatMessage({id: "entities.article.subCategory"}), field: "subcategory", filtering: false, sorting: false,
                            render: article => this.props.intl.formatMessage({id: "constants.SubCategory." + article.subcategory})},
                        {title: this.props.intl.formatMessage({id: "entities.article.articleCategory"}), field: "articleCategory", filtering: false, sorting: false,
                            render: article => this.props.intl.formatMessage({id: "constants.ArticleCategory." + article.articleCategory})},
                        {title: this.props.intl.formatMessage({id: "entities.article.description"}), field: "description", grouping: false, filtering: false},
                        {title: this.props.intl.formatMessage({id: "entities.article.image"}), field: "features", grouping: false, filtering: false,
                            render: article => renderImage(article, this.showArticleDetails)},
                        {title: this.props.intl.formatMessage({id: "entities.article.filterOptions"}), grouping: false, filtering: false,
                            render: article => this.filterOptions(article)},
                        {title: this.props.intl.formatMessage({id: "rentalLinen.assignment.remove"}), align: "right", cellStyle: {width: 180},
                            render: article => this.deleteButton(article), grouping: false, filtering: false}
                    ]}
                    data={data}
                    options={{
                        showTitle: false,
                        search: false,
                        paging: false,
                        draggable: false,
                        toolbar: false,
                        sorting: data.length > 0,
                        headerStyle: {
                            fontWeight: 'bold',
                            backgroundColor: bodyBackgroundColor,
                            color: texisionFontColorDark
                        }
                    }}/>
            </Grid>
        );
    }

    assortment = () => {
        const articles = this.state.assignedArticles;
        const type = this.state.area ? "area" : "professionalGroup";
        return (
            <div>

                <Typography variant="h2">
                    <FormattedMessage id={"rentalLinen.assignment.assignedArticles.title"}/>
                </Typography>

                <Typography variant="subtitle2">
                    <FormattedMessage id={"rentalLinen.assignment.assignedArticles." + type + ".subtitle"}/>
                </Typography>

                {!articles || articles.length === 0 ? <Card>
                    {this.props.intl.formatMessage({id: "rentalLinen.assignment.noArticles"})}
                </Card> : <div/>}

                {this.context.appData.categories
                    ?.sort((a,b) => this.props.intl.formatMessage({id: "constants.Category." + a})
                        .localeCompare(this.props.intl.formatMessage({id: "constants.Category." + b})))
                    ?.map(category => {
                    const filteredArticles = articles.filter(a => a.category === category);
                    if (filteredArticles && filteredArticles.length > 0) {
                        return (
                            <Grid item xs={12} key={category}>

                                <Accordion elevation={0}>

                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="-CONTENT"
                                        id="-HEADER">

                                        <Typography variant="h6">
                                            {this.props.intl.formatMessage({id: "constants.Category." + category})}
                                        </Typography>

                                    </AccordionSummary>

                                    <AccordionDetails>
                                        {this.rentalLinenTable(category)}
                                    </AccordionDetails>

                                </Accordion>

                            </Grid>
                        );
                    } else {
                        return <div/>;
                    }
                })}
            </div>
        );
    }

    filterOptionSelection = () => {
        let articleConfiguration = this.state.selectedArticleConfiguration;
        let articleSet;
        if (articleConfiguration) {
            if (articleConfiguration.articleCategory === ARTICLE_CATEGORIES.DUVET_COVER) {
                articleSet = this.findAssignedArticle(articleConfiguration.category, articleConfiguration.subCategory, ARTICLE_CATEGORIES.PILLOW_COVER);
            } else if (articleConfiguration.articleCategory === ARTICLE_CATEGORIES.PILLOW_COVER) {
                articleSet = this.findAssignedArticle(articleConfiguration.category, articleConfiguration.subCategory, ARTICLE_CATEGORIES.DUVET_COVER);
            }
        }

        return (
            <Grid container style={{marginTop: 20}} spacing={4}>

                <Grid item xs={12}>
                    <Button color="primary" variant="outlined" onClick={() => this.resetFilterOptions()} disabled={this.resetFilterOptionsButtonDisabled()}>
                        <FormattedMessage id="articleConfigurator.filterOptions.reset.button"/>
                    </Button>
                </Grid>

                {articleSet !== null && articleSet !== undefined &&
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" spacing={3}>
                            <Grid item xs={5}>
                                <Grid item xs={12} style={{marginTop: 10}}>
                                    <FormattedMessage id={"rentalLinen.assignment.article.set.button"}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={7}>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => this.setFilterOptionsFromArticle(articleSet, articleConfiguration)}>
                                    {articleSet.description}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {articleConfiguration?.filterCategories?.map(fc => <Grid item xs={12}>
                    <Grid container alignItems="flex-start" spacing={3}>

                        <Grid item xs={4}>
                            <Grid container spacing={1}>

                                <Grid item xs={12} style={{marginTop: 10}}>
                                    {this.props.intl.formatMessage({id: "constants.FilterCategory." + fc.filterCategoryType})
                                        + (fc.isMultipleChoice
                                            ? (" (" + this.props.intl.formatMessage({id: "rentalLinen.assignment.filter.multipleChoice.hint"}) + ")")
                                            : "*")}
                                </Grid>

                                <Grid item xs={12}>
                                    {fc.isMultipleChoice && fc.filterOptions?.length && fc.filterOptions.length > 1 && <Grid item>
                                        <Tooltip title={this.areAllOptionsSelected(fc)
                                            ? "" : this.props.intl.formatMessage({id: "rentalLinen.assignment.bidderFriendly.button"})}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                endIcon={this.areAllOptionsSelected(fc) ? null : <FaceRetouchingNaturalOutlined/>}
                                                onClick={() => this.areAllOptionsSelected(fc)
                                                    ? fc.filterOptions.forEach(o => this.handleOptionChanged(o, false, fc.isMultipleChoice))
                                                    : fc.filterOptions.forEach(o => this.handleOptionChanged(o, true, fc.isMultipleChoice))}>
                                                <FormattedMessage id={this.areAllOptionsSelected(fc)
                                                    ? "rentalLinen.assignment.filter.none" : "rentalLinen.assignment.filter.all"}/>
                                            </Button>
                                        </Tooltip>
                                    </Grid>}
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={7}>
                            <Grid container justifyContent="flex-start">
                                {fc.filterOptions?.map(option => <Grid item>
                                    {fc.isMultipleChoice
                                        ? <>
                                            <Checkbox
                                                disabled={fc.filterOptions.length === 1}
                                                checked={this.isOptionSelected(fc, option)}
                                                color="primary"
                                                onChange={(e) => this.handleOptionChanged(option, e.target.checked, true)}/>
                                            {option.name}
                                        </>
                                        : <FormControlLabel
                                            disabled={fc.filterOptions.length === 1}
                                            checked={this.isOptionSelected(fc, option)}
                                            value={option}
                                            style={{marginLeft: 0}}
                                            onChange={(e, checked) => this.handleOptionChanged(option, checked, false)}
                                            control={<Radio color="primary"/>}
                                            label={option.name}/>
                                    }
                                </Grid>)}
                            </Grid>
                        </Grid>

                        <Grid item xs={1}>
                            {fc.isMultipleChoice && this.areAllOptionsSelected(fc)
                                && <Tooltip title={this.props.intl.formatMessage({id: "rentalLinen.assignment.bidderFriendly.tooltip"})}>
                                    <FaceRetouchingNaturalOutlined color="primary" style={{marginTop: 10}}/>
                                </Tooltip>}
                        </Grid>

                    </Grid>

                </Grid>)}

                <Grid item xs={12} style={{marginTop: 20}}>
                    <FormattedMessage id="articleConfigurator.filterOptions.mandatory"/>
                </Grid>

            </Grid>
        );
    }

    isOptionSelected = (filterCategory, option) => {
        return !!this.state.newFilterCategories?.find(n => n.filterCategoryType === filterCategory.filterCategoryType)
            ?.filterOptions?.find(o => o.filterCategoryId === filterCategory.id && o.name === option.name && o.id === option.id);
    }

    areAllOptionsSelected = (filterCategory) => {
        for (let option of filterCategory.filterOptions) {
            if (!this.isOptionSelected(filterCategory, option)) {
                return false;
            }
        }
        return true;
    }

    setFilterOptionsFromArticle = (articleSet, articleConfiguration) => {
        articleConfiguration.filterCategories.forEach(targetCategory => {
            if (targetCategory.filterCategoryType !== "SURFACE_MEASURE") {
                targetCategory.filterOptions.forEach(targetOption => {
                    let sourceOption = articleSet.filterOptions
                        .find(o => o.name === targetOption.name);
                    let checked = sourceOption !== null && sourceOption !== undefined;
                    this.handleOptionChanged(targetOption, checked, targetOption.isMultipleChoice);
                });
            }
        });
    }
    render() {
        if (!this.props.location.state) {
            return (
                <Redirect to={RENTAL_LINEN_ROUTE}/>
            );
        }
        return (
          <>

              {this.state.articleForDetailView && <ArticleDetail
                  article={this.state.articleForDetailView}
                  showDialog={!!this.state.articleForDetailView}
                  handleDialogClose={() => this.setState({articleForDetailView: null})}/>}

              <TexisionDialog
                  open={this.state.showFilterDialog}
                  type={DIALOG_TYPE_INFO}
                  titleId="rentalLinen.assignment.selectFilter.title"
                  subtitleId="rentalLinen.assignment.selectFilter.subtitle"
                  cancelId="commons.cancel.button"
                  onCancel={() => this.handleFilterCategoriesCancelled()}
                  actionId="commons.save.button"
                  onAction={() => this.handleFilterCategoriesChanged()}
                  content={this.filterOptionSelection()}
              />

              <Link to={{pathname: RENTAL_LINEN_ROUTE}}>
                  &lt; &lt; &nbsp;<FormattedMessage id="commons.back.button"/>
              </Link>

              <Typography className="withSpace" variant="h1">
                  {this.title()}
              </Typography>

              <Typography variant="subtitle1">
                  {this.subtitle()}
              </Typography>

              {!this.props.readOnly && this.configurator()}

              {!this.props.readOnly && <div style={{height: 30}}/>}

              {this.assortment()}

          </>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(RentalLinenAssignment)));
