import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {CookiesProvider} from "react-cookie";
import './index.css';
import packageJson from "../package.json";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './components/apps/App';
import CacheBuster from "react-cache-buster";

async function init() {
    console.log("Hello, you are running Texision in " + process.env.NODE_ENV + " mode");
    ReactDOM.render(
        <React.StrictMode>
            <CookiesProvider>
                <CacheBuster
                    currentVersion={packageJson.version}
                    isEnabled={true}
                    isVerboseMode={false}
                    metaFileDirectory={"."}>
                    <App/>
                </CacheBuster>
            </CookiesProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// hide error overlay before it becomes visible
if (process.env.NODE_ENV === 'development') {
    window.addEventListener('error', () => {
        const overlay = document.getElementById('webpack-dev-server-client-overlay');
        if (overlay) overlay.style.display = 'none';
    });

    window.addEventListener('unhandledrejection', () => {
        const overlay = document.getElementById('webpack-dev-server-client-overlay');
        if (overlay) overlay.style.display = 'none';
    });
}

