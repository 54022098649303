import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

import '../apps/App.css';
import '../../css/ProjectDashboard.css';

import {DIALOG_TYPE_INFO, OPERATION_TYPES, PROJECT_STATUS_IN_PROGRESS} from '../../util/Constants';
import TexisionDialog from '../uiLibrary/TexisionDialog';
import {GeneralContext} from "../contexts/GeneralContext";
import {withSnackbar} from 'notistack';
import OperationPanel from "./OperationPanel";
import {copyOperation} from "../../services/OperationService";
import {createErrorMessage, createSuccessMessage} from "../../util/Util";
import {warningColor} from "../../util/ColorTheme";
import WarningIcon from '@mui/icons-material/Warning';
import {Grid} from "@mui/material";
import Spacer from "../uiLibrary/Spacer";

class ProjectCreateOrCopyDialog extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => {
        const { operation } = this.props;
        return {
            projectName: "",
            projectStatus: PROJECT_STATUS_IN_PROGRESS,
            profileIds: operation.profileIds,
            isPrivate: operation.isPrivate,
        }
    }

    handleAbort = () => {
        this.setState(this.initState());
        this.props.closeDialog();
    }

    buildOperation = () => {
        return {
            isPrivate: this.state.isPrivate,
            operationType: OPERATION_TYPES.ORIGIN_TENDER,
            version: 0,
            profileIds: this.state.profileIds,
            activeProject: {
                name: this.state.projectName,
                version: 0,
                status: PROJECT_STATUS_IN_PROGRESS
            },
        };
    }

    onCopyOperation = async() => {
        let projectName = this.state.projectName;
        const operation = this.buildOperation();

        await copyOperation(this.context, operation,
            () => {
                createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.newOperation.copy.success"}, {projectName: projectName}), this.props);
            },
            () => {
                createErrorMessage(this.props.intl.formatMessage({id: "cockpit.newOperation.copy.error"}, {projectName: projectName}), this.props);
            });
        this.props.closeDialog();
    }

    checkDialogButtonDisabled = () => {
        return !this.state.projectName || !this.state.profileIds?.length;
    }

    render() {
        return (
            <TexisionDialog
                type={DIALOG_TYPE_INFO}
                open={this.props.open}
                titleId={"cockpit.newOperation.title"}
                subtitleId={"cockpit.newOperation.subtitle"}
                replacements={{tender: this.props.operation?.activeProject?.name}}
                actionDisabled={this.checkDialogButtonDisabled()}
                actionId="navBar.newOperation.action"
                cancelId="commons.cancel.button"
                onAction={() => this.onCopyOperation()}
                onCancel={() => this.handleAbort()}
                content={
                    <Grid container wrap="nowrap" style={{marginTop:20}}  spacing={0} direction="column" alignItems="center" justifyContent="center">
                        <Grid item>
                            <OperationPanel
                                isTender={true}
                                projectId={this.projectId}
                                projectName={this.state.projectName}
                                onNameChange={(projectName) => this.setState({projectName})}
                                showProfile={false}
                                showProcurement={false}
                                isPrivate={this.state.isPrivate}
                                onIsPrivateChange={(isPrivate) => this.setState({isPrivate})}
                            />
                        </Grid>
                        <Spacer size={20}/>
                        <Grid item xs alignItems="center" sx={{ display: 'flex' }}>
                            <WarningIcon style={{marginRight: 10, color: warningColor}}/>
                            <FormattedMessage id="cockpit.newOperation.warning"/>
                        </Grid>
                    </Grid>
                }
            />
        );
    }
}

export default withSnackbar(injectIntl(ProjectCreateOrCopyDialog));
